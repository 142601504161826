require("../styles/_Common.scss");

import React from "react";

class IE9 extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            ie9 : false
        };
    }
    
    componentDidMount() {
        if ( getIEVersion() < 10 ) {
            this.setState({
                ie9 : true
            })
        }
    }

    
    render() {   
        
        if (this.state.ie9)  {
            return (
                <div className = "container center_this margin_50_0">
                    Sorry, due to security issues, we cannot support Internet Explorer versions less than 10.  Please upgrade your browser to a secure version.  Email us at: <a href="mailto:admin@tradesmantoken.com">admin@tradesmantoken.com</a> for support.
                </div>
            )
        } else {
            return (
                <span />
            )
        }
    }
}


export default IE9;
