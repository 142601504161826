require("../styles/_Common.scss");

import React from "react";

class Home extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            priceSpan1  : "0.00007 ETH",
            priceSpan2  : "0.00007 ETH"
        };
        
       
    }
    
    componentDidMount() {
        
        if ( getIEVersion() < 10 ) {
            return;
        }
        
        var ajaxReq = new XMLHttpRequest();
            ajaxReq.addEventListener(
                "load", 
                function() {
                    var responseJSON = JSON.parse(ajaxReq.responseText);
            
                    console.log(responseJSON);
                    this.setState(
                        {
                            priceSpan1  : "0.00007 ETH (~$" + Math.round(responseJSON["USD"]/14) / 1000 + ")",
                            priceSpan2  : "0.00007 ETH (~$" + Math.round(responseJSON["USD"]/14) / 1000 + ")"
                        }
                    );
                    return;
                }.bind(this), 
                false
            );
            ajaxReq.addEventListener(
                "error", 
                function(xhr, status, err) {
                    console.error("error", status, err.toString());
                    return;
                }.bind(this), 
                false
            );
            ajaxReq.open( "get", "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD", true );
            ajaxReq.send();
    } 
    

    render() {

        return (
            <div>
                <div className = "jumbotron jumbotron-fluid bg-inverse text-white text-center">
                    <div className = "container">
                        <h1 className = "display-3">Finally...a Cryptocurrency for the Rest of Us.</h1>
                        <div className = "row">
                            <div className = "col-md-8 push-md-2">
                                <hr className = "jumbotron-hr margin_top_3rem" />
                                <p className = "lead">
                                    A token specifically made by tradesmen, for tradesmen.
                                </p>
                                <p>See below for a special introductory offer!</p>
                                <hr className = "jumbotron-hr" />

                            </div>
                        </div>
                    </div>
                </div>
            
            
            
                <div className="container">
            
                <h3>What is a Tradesman Token?</h3>
    
                For millennia, tradesmen (contractors, graphic artists, painters, electricians, massage therapists, plumbers, landscapers, photographers, website developers, hairdressers, ... you get the idea) have been carrying out a messy, not-always-fair system of barter amongst themselves. &nbsp;Usually, a direct trade doesn&apos;t work, as the value of services doesn&apos;t match, or one party doesn&apos;t require the services of the other, but could use the services of a third party. &nbsp;Enter Tradesman Token!
                <br /><br />
            
                We are building a brand new, searchable, worldwide barter network, with <u>zero</u> fees. &nbsp;How can we do that? &nbsp;All trades are done in the blockchain; &nbsp;We are not a party to the transactions. &nbsp;There are no intermediaries!
                <br /><br />
    
                <h3>How does it work?</h3>
    
                We are distributing these tokens among the early adopters, so they can be used as currency to tally the value of traded services on the blockchain. &nbsp;What does that mean? &nbsp;Hopefully, Tradesman Tokens (TRD) will be used in the network of tradespersons in lieu of cash. &nbsp;Imagine paying 100 tokens for a massage and 50 tokens to a website SEO specialist, while receiving 150 tokens for your services to an auto mechanic. &nbsp;Yeah, the old system seems archaic already, doesn&apos;t it?
                <br /><br />
    
                <h3>Where is your white paper?</h3>
    
                The world doesn&apos;t need another cryptocurrency white paper. &nbsp;Here are the particulars:
                <br /><br />
                There will only be 100,000,000 Tradesman Tokens minted, and that is ALL there will ever be. &nbsp;Experts estimate that roughly $15 billion worth of bartered goods and services exchange hands every year in the US alone. &nbsp;So, these tokens can only cover a very small percentage of the trades (0.67%). &nbsp;Given time and wide adoption, the laws of supply and demand could make these tokens very desirable. &nbsp;Of course, there are no guarantees, but what have you got to lose?
                <br /><br />
    
                The Tradesman Token contract runs on the Ethereum blockchain, and uses battle-tested code provided by ethereum.org. &nbsp;Yes, it runs on the very same blockchain as Ether tokens, without any involvement on our part. &nbsp;It can never be mined or minted, so supply will always be limited.
                <br /><br />
    
                The project administrator reserves the right to award tokens as bounties to help promote the project, and the administrator will retain up to 10% of the tokens. &nbsp;Obviously, it is not our intention to make money selling these tokens at ICO, but to build a platform that gives the tokens market value. &nbsp;Our interests are aligned -- we share the same goal! &nbsp;If you recognize the promise in this concept, then please join us here at the ICO.
                <br /><br />
    
                View the Tradesman contract on <a href="https://etherscan.io/address/0xE9e03497fBF8f9463b2124fe2c8CeB8C3C92d8CD" target="_blank">etherscan.io</a>
                <br />
                View the Tradesman contract on <a href="https://ethplorer.io/address/0xe9e03497fbf8f9463b2124fe2c8ceb8c3c92d8cd" target="_blank">ethplorer.io</a>
                <br />
                View the Tradesman contract on <a href="https://etherchain.org/account/0xE9e03497fBF8f9463b2124fe2c8CeB8C3C92d8CD" target="_blank">etherchain.org</a>
                <br /><br />
                
                
    
                <h3>OK, how do I get some?</h3>
                
                <h2 className="padding_20"><span className="red bold">Current $TRD Price: </span><span id="currentPrice2">{ this.state.priceSpan2 }</span></h2>
    
                Simple. &nbsp;If you currently barter for goods and services, we will send you 1000 tokens just for <a href="/register">registering</a>! &nbsp;You can also purchase extra Tradesman Tokens (TRD) in our Initial Coin Offering (ICO) at <span id="currentPrice">{ this.state.priceSpan1 }</span> each.  &nbsp;In other words, 1 ETH buys 14,000 TRD! &nbsp;Here are some examples of the types of members we are seeking:
                <br /><br />
    
                <ul className="padding_left_2em">
                    <li> Are you in the trades, looking to expand your business?</li>
                    <li> Do you advertise in the Barter section on Craigslist or similar?</li>
                    <li> Do you belong to an organized barter group (ITEX, Simbi, Tradeaway, FreeCycle, IMS, BarterOnly, etc.)?</li>
                    <li> Have you provided free or discounted service to a nonprofit organization (school, church, civic, etc.) in the past?</li>
                    <li> Have you done a direct trade with another tradesperson in the past?</li>
                </ul>
    
                If so, <a href="/register">register</a> for your free tokens!
                <br /><br />
    
                <h3>Does this give me a way to avoid income tax?</h3>
    
                As you know, whether you pay in fiat currency, services, gold, or cryptocurrency, the IRS still wants its cut. &nbsp;As now, you are still required to report your trades to the IRS. &nbsp;That part hasn&apos;t changed.
                <br /><br />
    
                Let&apos;s do this! &nbsp; <a href="/how-to-purchase">Get Started</a>
                <br />
    
            </div>
        </div>

      )            
   }
}


export default Home;
