require("../styles/_Common.scss");
require("../styles/_Forms.scss");

import React from "react";

//const queryString = require('query-string');
// var token = queryString.parse(props.location.token);

class ResetPassword extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            generalErrorText    : '',
            passwordErrorText   : '',
            passwordErrorText2  : '',
            passwordErrorText3  : '',
            emailErrorText      : '',
            sendButtonText      : 'submit',
            userMsg             : ''
        };
        
        this.handlePasswordClick    = this.handlePasswordClick.bind(this);
        this.handleEmailClick       = this.handleEmailClick.bind(this);
        this.doNotSubmit            = this.doNotSubmit.bind(this);
    }


    handlePasswordClick(e) {
        e.preventDefault();
        
        var csrf_token              = this.refs.csrf_token.value;
        var password1               = this.refs.password1.value;
        var password2               = this.refs.password2.value;
        var token                   = window.location.pathname.split('/')[2];   
        // ["", "reset_password", "qwertyuiolkjh", ""]
        
        this.setState(
            {
                                    generalErrorText:       '',
                                    passwordErrorText:      '',
                                    passwordErrorText2:     '',
                                    passwordErrorText3:     ''
            }
        )
        
        var allowSubmit = true;
        
        if ((password1.replace(/\s/g, '') == '') || (password2.replace(/\s/g, '') == '')) {
            allowSubmit = false;
            this.setState(
                {
                                    passwordErrorText:      "Both password fields required."
                }
            )
        }
        
        if ((password1.indexOf(' ') != -1) || (password2.indexOf(' ') != -1)) {
            allowSubmit = false;
            this.setState(
                {
                                    passwordErrorText2:      "Spaces not allowed in password."
                }
            )
        }
        
        if (password1 != password2) {
            allowSubmit = false;
            this.setState(
                {
                                    passwordErrorText3:      "Passwords entered do not match."
                }
            )
        }
        
        if (allowSubmit) {
                
            fetch("/reset_password/" + token + "/", {
                method    : 'POST',
                headers   : {
                    'Accept'        : 'application/json',
                    'Content-Type'  : 'application/json; charset=UTF-8',
                    'X-CSRFToken'   : csrfToken
                },
                mode      : 'same-origin',
                body      : JSON.stringify(
                    {
                        password1   : password1,
                        password2   : password2
                    }
                )
            })
            .then(
                function(response) {
                    if (response.status !== 200) {
                        console.log('/reset_password/ Status Code: ' + response.status);
                        return;
                    }

                    response.json().then(function(responseJSON) {
                        //console.error(responseJSON)
                        
                        if ( responseJSON["msg"] == "success" ) {
                            this.setState( {
                                userMsg   : "Password reset complete.  You may now log in."
                            })
                            return;
                        } else {
                            // give user msg
                            this.setState( {
                                "generalErrorText": responseJSON["msg"]
                            });
                        }
                    }.bind(this));
                }.bind(this)
            )
            .catch(function(err) {
                console.log('/ajax_login/ Fetch Error :-S', err);
                return;
            });
        }
        return;
        
      }
      
      handleEmailClick(e) {
          e.preventDefault();
          
          var email =                     this.refs.email.value.trim();
          var csrf_token =                this.refs.csrf_token.value;
        
          this.setState(
              {
                                          generalErrorText:       '',
                                          emailErrorText:         ''
              }
          )
        
          var allowSubmit = true;
        
          if (email.replace(/\s/g, '') == '') {
              allowSubmit = false;
              this.setState(
                  {
                                      emailErrorText:      "Email address required."
                  }
              )
          } else {
              var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\"\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              if (!re.test(email)) {
                  allowSubmit = false;
                  this.setState(
                      {
                                      emailErrorText:      "Invalid email format."
                      }
                  )
              }
          }
          
          if (allowSubmit) {
                  
              fetch("/reset_password/", {
                  method    : 'POST',
                  headers   : {
                      'Accept'        : 'application/json',
                      'Content-Type'  : 'application/json; charset=UTF-8',
                      'X-CSRFToken'   : csrfToken
                  },
                  mode      : 'same-origin',
                  body      : JSON.stringify(
                      {
                          email : email
                      }
                  )
              })
              .then(
                  function(response) {
                      if (response.status !== 200) {
                          console.log('/reset_password/ Status Code: ' + response.status);
                          return;
                      }

                      response.json().then(function(responseJSON) {
                          //console.error(responseJSON)
                    
                          if ( responseJSON["msg"] == "success" ) {
                              this.setState( {
                                  userMsg   : "Please check your email for password reset instructions."
                              })
                              return;
                          } else {
                              // give user msg
                              this.setState( {
                                  "generalErrorText": responseJSON["msg"]
                              });
                          }
                      }.bind(this));
                  }.bind(this)
              )
              .catch(function(err) {
                  console.log('/ajax_login/ Fetch Error :-S', err);
                  return;
              });
          }
          return;
      }
      
      doNotSubmit(e) {
          e.preventDefault();
          console.log('prevented');
          return false;
      }

    render() {
        
        if (this.state.userMsg) {
            return(
                <div className = "container center_this margin_30_0_0">
                    { this.state.userMsg }
                </div>
            )
        }
        
        var step                = this.props.globalProps.varsJSON.step;
        var next                = this.props.globalProps.varsJSON.next;
        var csrf_token          = csrfToken;
        
        var formDOM             = null,
            generalErrorDOM     = null,
            passwordErrorDOM    = null,
            passwordErrorDOM2   = null,
            passwordErrorDOM3   = null,
            emailErrorDOM       = null;
        
        if (this.state.generalErrorText) {
            generalErrorDOM =       <div className="errors">{ this.state.generalErrorText }</div>;
        }
        if (this.state.passwordErrorText) {
            passwordErrorDOM =      <div className="errors">{ this.state.passwordErrorText }</div>;
        }
        if (this.state.passwordErrorText2) {
            passwordErrorDOM2 =      <div className="errors">{ this.state.passwordErrorText2 }</div>;
        }
        if (this.state.passwordErrorText3) {
            passwordErrorDOM3 =      <div className="errors">{ this.state.passwordErrorText3 }</div>;
        }
        if (this.state.emailErrorText) {
            emailErrorDOM =          <div className="errors">{ this.state.emailErrorText }</div>;
        }

        if (step == "getEmail") {
            formDOM =   <div className = "margin_30_0_0">
                            <h3>Reset Password</h3>
                            <div className = "align_left">
                                Email address:
                            </div>
                            { generalErrorDOM }
                            { emailErrorDOM }
                            <input ref="email" className="form_input ht_175" type="text" /> <br />
                            <input  type            = "button" 
                                    ref             = "submitButton" 
                                    className       = "button_sm form_submit center_this" 
                                    onClick         = { this.handleEmailClick } 
                                    defaultValue    = { this.state.sendButtonText }
                            />
                        </div>
        } else if (step == "enterNewPassword") {
            formDOM =   <div className = "margin_30_0_0">
                            <h3>Enter New Password</h3>
                            <div className="profile_field">
                                <div className="password_1_field">
                                    <div>New Password:</div>
                                    <div>
                                        <input ref="password1" className="form_input ht_175" type="password" />
                                    </div> 
                                </div>
        
                                <div className="password_2_field">
                                    <div>Re-enter New Password:</div>
                                    <div>
                                        <input ref="password2" className="form_input ht_175" type="password" />
                                    </div> 
                                </div>
        
                                <div>
                                    { generalErrorDOM }
                                    { passwordErrorDOM }
                                    { passwordErrorDOM2 }
                                    { passwordErrorDOM3 }
                                </div>
                                    
                                <input  type            = "button" 
                                        ref             = "submitButton" 
                                        className       = "button_sm form_submit center_this" 
                                        onClick         = { this.handlePasswordClick } 
                                        defaultValue    = { this.state.sendButtonText }
                                />
                            </div> 
                        </div>
        } else if (step == "resetPasswordComplete") {
            formDOM =   <div>
                            <div className="reset_password_header">Password Reset Complete</div>
                            <div className="password_form_login"><a href={`/login/?next=${ next }`}>Log in</a></div>
                        </div>
        } else if (step == "tokenError") {
            formDOM =   <div className = "center_this">
                            { this.props.msg }
                        </div>
        } else if (step == "checkEmail") {
            formDOM =   <div className = "center_this">
                            Please check your email for the link to reset your password.
                        </div>
        } else {
            formDOM =   <div className = "center_this">
                            { this.state.generalErrorText }
                        </div>
        }

        return  (
            <div className = "container center_this">
                <div className = "margin_0_auto table_resp_sm">
                    <form   ref     = "theForm" 
                            action  = "" 
                            method  = "post" 
                            encType = "multipart/form-data"
                            onSubmit={ this.doNotSubmit }
                    >
                        <input ref="csrf_token" type="hidden" defaultValue={ this.props.globalProps.csrfToken } />

                        { formDOM }

                    </form>
                </div>
            </div>
        );
    }
}


export default ResetPassword;
