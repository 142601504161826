
import Home                 from "./Home.jsx";
import HowTo                from "./HowTo.jsx";
import Contact              from "./Contact.jsx";
import Media                from "./Media.jsx";
import RegisterForm         from "./RegisterForm.jsx";
import Profile              from "./Profile.jsx";
import ProfileForm          from "./ProfileForm.jsx";
//import Referrals            from "./Referrals.jsx";
import Message              from "./Message.jsx";
import ResetPassword        from "./ResetPassword.jsx";
import Search               from "./Search.jsx";
import AddWallet            from "./AddWallet.jsx";
import ViewWallet           from "./ViewWallet.jsx";
import EditWallet           from "./EditWallet.jsx";
import BulkEmailForm        from "./BulkEmailForm.jsx";
import ABIchecker           from "./ABIchecker.jsx";
import Privacy              from "./Privacy.jsx";
import Terms                from "./Terms.jsx";
import Header               from "./Header.jsx";
import IE9                  from "./IE9.jsx";
import Footer               from "./Footer.jsx";
import ABIchecker0xBounty   from "./ABIchecker0xBounty.jsx";

// to get rid of 'Set' error in IE 10.  adds 11k to size
import 'core-js/es/map';
import 'core-js/es/set';

// require('es6-promise').polyfill();

import React                from 'react';
import ReactDOM             from 'react-dom';

// uses HTML5 history API
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom'



class MainContainer extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = {
            currentUser:        userJSON
        };
        
        this.handleLogin  = this.handleLogin.bind(this);
        this.handleLogout = this.handleLogout.bind(this);
    }


    handleLogin(currentUserJSON) {
        this.setState( {
            currentUser :       currentUserJSON         
        });
    }
    
    handleLogout() {
        this.setState( {
            currentUser :   {
                                name    : "anonymous",
                                avatar  : "anonymous",
                                https   : "on",
                                userID  : 999
                             }         
        });
    }
    
    
    render() {  

        var globalProps = {
            varsJSON        : varsJSON,
            csrfToken       : csrfToken,
            GCS_IMAGE_PATH  : GCS_IMAGE_PATH,
            handleLogin     : this.handleLogin,
            handleLogout    : this.handleLogout,
            currentUser     : this.state.currentUser
        };

        return (
            <div>
                <Header {...this.state}  globalProps = { globalProps } />
                <IE9 />

                <BrowserRouter>
                    <Switch>
                        {/* main page */}
                        <Route path = "/howto"                      component = {HowTo} />
                        <Route path = "/how-to"                     component = {HowTo} />
                        <Route path = "/how-to-buy"                 component = {HowTo} />
                        <Route path = "/how-to-purchase"            component = {HowTo} />
                        <Route path = "/contact"                    component = {Contact} />
                        <Route path = "/media"                      component = {Media} />
                        <Route path = "/search"                     component = {Search} />


                        {/* auth urls */}
                        <Route exact path='/register'               render={(props) => ( <RegisterForm {...props}   globalProps={globalProps} /> )}/>
                        
                        <Route path = "/activate/:activationKey/"   render={(props) => ( <Message {...props}        globalProps={globalProps} /> )}/>
                        
                        <Route exact path="/profile/:userID"        render={(props) => ( <Profile {...props}        globalProps={globalProps} /> )}/>
                        <Route exact path="/profile/:userID/edit"   render={(props) => ( <ProfileForm {...props}    globalProps={globalProps} /> )}/>

                        <Route path = "/reset_password/"             render={(props) => ( <ResetPassword {...props}  globalProps={globalProps} /> )}/>
                        <Route path = "/reset_password/:token/"     render={(props) => ( <ResetPassword {...props}  globalProps={globalProps} /> )}/>
                        
                        //<Route path = "/referrals/:userID"          render={(props) => ( <Referrals {...props}  globalProps={globalProps} /> )}/>

                        {/* privacy, terms, about us, etc */}
                        <Route path = "/privacy"                    component = {Privacy} />
                        <Route path = "/terms"                      component = {Terms} />

                            
                        {/* wallet admin */}
                        <Route path = "/trdadmin/add_wallet"           render={(props) => ( <AddWallet {...props}      globalProps={globalProps} /> )}/>
                        <Route path = "/trdadmin/view_wallet/:key"     render={(props) => ( <ViewWallet {...props}     globalProps={globalProps} /> )}/>
                        <Route path = "/trdadmin/edit_wallet/:key"     render={(props) => ( <EditWallet {...props}     globalProps={globalProps} /> )}/>
                        <Route path = "/trdadmin/bulk_email"           render={(props) => ( <BulkEmailForm {...props}  globalProps={globalProps} /> )}/>
                        <Route path = "/trdadmin/data"                 render={(props) => ( <ABIchecker {...props}     globalProps={globalProps} /> )}/>
                        <Route path = "/0xBountyCheck"                 render={(props) => ( <ABIchecker0xBounty {...props}     globalProps={globalProps} /> )}/>
                        <Route path = "/trdadmin/test"                 render={(props) => ( <Message {...props}        globalProps={globalProps} /> )}/>
                        <Route path = "/trdadmin/fixit"                render={(props) => ( <Message {...props}        globalProps={globalProps} /> )}/>
                        
                        <Route path = "/need_login"                render={(props) => ( <Message {...props}        globalProps={globalProps} /> )}/>
                        
                        

                        {/* redirects */}
                        //<Redirect from = "/path"                    to = "/path/"/>

                        <Route path = "/"                           component = {Home} />

                        <Route path = "*"                           component = {Home} />
                    </Switch>
                </BrowserRouter>
                        
                <Footer />

            </div>
        )
    }
}

ReactDOM.render((
    <MainContainer />
), document.getElementById('react_content'));

export default MainContainer;
