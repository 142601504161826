                    require("../styles/_Common.scss");

import React from "react";


class Message extends React.Component {
    
  render() {
      // React is safe, and encodes <> to prevent XSS injections
      // so, we put separate paragraphs in a list
      var textAlign =               this.props.globalProps.varsJSON.textAlign;
      var msgParagraphs =           this.props.globalProps.varsJSON.msg.map(
          function (paragraph, i) {
            return (
                <div    className = "margin_10_0" 
                        key={`msg_${i}`}
                        dangerouslySetInnerHTML={{ __html: paragraph }} 
                />
            );
      }.bind(this));

      return (
          <div className="container margin_50_0" style={{"textAlign":textAlign}}>
              { msgParagraphs }
          </div>
      )            
  }
}


export default Message;
