require("../styles/_Common.scss");
require("../styles/_Forms.scss");
                

import React from "react";

var ReactCSSTransitionGroup = React.CSSTransitionGroup;


class Profile extends React.Component {
    


      // this.getParams() gets params from query string
      // this.props is passed as spread: {...this.state} from parent
      // this.state is loaded with props in compWillRcvProps

    constructor(props) {
        super(props);
        this.state = {
            userFinderText  : null,
            userFinderStyle : { "display": "none" },
            searchResults   : null,
        };
        
        this.handleEdit             = this.handleEdit.bind(this);
        this.handleReferralsClick   = this.handleReferralsClick.bind(this);
    }
    
    //contextTypes: {
    //    router: React.PropTypes.func
    //}

    handleReferralsClick() {
        
        var ajaxReq =             new XMLHttpRequest();
            ajaxReq.addEventListener(
                "load", 
                function() {
                    var responseJSON =          JSON.parse(ajaxReq.responseText);
                    console.log(responseJSON);
                    
                    this.setState( {
                            searchResults       : responseJSON,
                        });

                    this.busyFetching = false;
                    return;
               
                }.bind(this), 
                false
            );
            ajaxReq.addEventListener(
                "error", 
                function(xhr, status, err) {
                    console.error("/referrals/" + this.props.globalProps.varsJSON.profileJSON.userID, status, err.toString());
                    this.setState(
                        {
                            generalErrorText    : responseJSON,
                        }
                    );
                    this.busyFetching = false;
                    return;
                }.bind(this), 
                false
            );
            ajaxReq.open( "get", "/referrals/" + this.props.globalProps.varsJSON.profileJSON.userID, true );
            ajaxReq.send();
    }
    
    componentWillMount() {

    }
    
    componentDidMount() {    

    }
    
    handleEdit() {
        window.location.href = "/profile/" + this.props.globalProps.currentUser.userID + "/edit";
    }
    
    render() {
      
        var nameDOM             = null,
            orgDOM              = null,
            addressDOM          = null,
            cityStateDOM        = null,
            phoneDOM            = null,
            emailDOM            = null,
            urlDOM              = null,
            twitDOM             = null,
            fbDOM               = null,
            instaDOM            = null,
            //sloganDOM           = null,
            descDOM             = null,
            walletAddressDOM    = null,
            categoriesDOM       = null,
            banUserDOM          = null,
            errorDOM            = null,
            postLoadMsg         = null,
            profileActions      = null,
            referralsDOM        = null;
      
        var profileJSON =  this.props.globalProps.varsJSON.profileJSON;
        
        
        if ( this.state.searchResults ) {
            var referrals = this.state.searchResults;
            
            var referralsDOM  = (referrals[0] != 'unauthorized access')
                    ?   <div>
                            <h2 className = "margin_30_0_0">
                                Your referrals (not verified)
                            </h2>
                            <table className = "table table-striped">
                                <tbody>
                                    <tr className = "bold">
                                        <td className = "padding_0_4 bold">
                                            full name
                                        </td>
                                        <td className = "padding_0_4 bold">
                                            org
                                        </td>
                                        <td className = "padding_0_4 bold">
                                            activated
                                        </td>
                                        <td className = "padding_0_4 bold">
                                            email
                                        </td>
                                        <td className = "truncate" className = "padding_0_4 bold">
                                            description
                                        </td>
                                        <td className = "padding_0_4 bold">
                                            city
                                        </td>
                                        <td className = "padding_0_4 bold">
                                            country
                                        </td>
                                    </tr>

                                { referrals.map( function(referral, index) {
                                    return (
                                        <tr key = {`tr_${ index}`} 
                                            onClick = { function() {
                                                    window.location.href = "/profile/" + referral[0];
                                                }
                                            }
                                            className = "pointer"
                                        >
                                            <td className = "padding_0_4">
                                                {referral[1]}
                                            </td>
                                            <td className = "padding_0_4">
                                                {referral[2]}
                                            </td>
                                            <td className = "padding_0_4">
                                                {referral[3]}
                                            </td>
                                            <td className = "padding_0_4">
                                                {referral[4]}
                                            </td>
                                            <td className = "padding_0_4">
                                                {referral[5]}
                                            </td>
                                            <td className = "padding_0_4">
                                                {referral[6]}
                                            </td>
                                            <td className = "padding_0_4">
                                                {referral[7]}
                                            </td>
                                        </tr>
                                    );
                                })
                                }
                                </tbody>
                            </table>
                        </div>
                    :   <h2 className = "margin_30_0_0">
                            You are not authorized to view this list
                        </h2>;
        }
             

        if ((profileJSON.msg.indexOf("not found") != -1) || (profileJSON.msg.indexOf("not activated") != -1)) {
            return (
                <div className="profile_header">{ profileJSON.msg }</div>
            )
        } else {

            if (this.props.globalProps.currentUser.https == "superuser") {
              banUserDOM = <div style={{"textAlign":"center","padding":"25px"}}>
                              <a href={`/trdadmin/ban_user/${ profileJSON.userID }`}>ban this user</a><br />
                           </div>;
        }


        if (profileJSON["name"] != undefined) {
            nameDOM =       <div>
                                { profileJSON["name"] }
                            </div>;
        }
        
        if (profileJSON["org"] != undefined) {
            orgDOM =        <div>
                                { profileJSON["org"] }
                            </div>;
        }
        
        if (profileJSON["address"] != undefined) {
            addressDOM =    <div>
                                { profileJSON["address"] }
                            </div>;
        }
        
        if (profileJSON["cityStateCountry"] != undefined) {
            cityStateDOM =  <div>
                                { profileJSON["cityStateCountry"] }
                            </div>;
        }
        
        if (profileJSON["phone"] != undefined) {
            phoneDOM =      <div className = "margin_10_0">
                                phone: &nbsp;{ profileJSON["phone"] }
                            </div>;
        }
        
        if (profileJSON["email"] != undefined) {
            emailDOM =      <div className = "margin_10_0">
                                email: &nbsp;<a href={`mailto:${profileJSON["email"]}`}>{ profileJSON["email"] }</a>
                            </div>;
        }
        
        if (profileJSON["url"] != undefined) {
            urlDOM =        <div className = "margin_10_0">
                                website: &nbsp;<a href = { profileJSON["url"] } target="_blank">{ profileJSON["url"] }</a>
                            </div>;
        }
        
        if (profileJSON["twitterUrl"] != undefined && profileJSON["twitterUrl"] != '') {
            twitDOM =        <div className = "margin_10_0">
                                twitter: &nbsp;<a href = { profileJSON["twitterUrl"] } target="_blank">{ profileJSON["twitterUrl"] }</a>
                            </div>;
        }
        
        if (profileJSON["facebookUrl"] != undefined && profileJSON["facebookUrl"] != '') {
            fbDOM =        <div className = "margin_10_0">
                                facebook: &nbsp;<a href = { profileJSON["facebookUrl"] } target="_blank">{ profileJSON["facebookUrl"] }</a>
                            </div>;
        }
        
        if (profileJSON["instagramUrl"] != undefined && profileJSON["instagramUrl"] != '') {
            instaDOM =        <div className = "margin_10_0">
                                instagram: &nbsp;<a href = { profileJSON["instagramUrl"] } target="_blank">{ profileJSON["instagramUrl"] }</a>
                            </div>;
        }
        
        
        if (profileJSON["desc"] != undefined) {
            descDOM =       <div className = "margin_10_0 desc">
                                { profileJSON["desc"] }
                            </div>;
        }
    
        
        if (profileJSON["categories"] != undefined) {                
            categoriesDOM =  <div className = "padding_16_0">
                                 Listed in: { profileJSON["categories"].join(", ") }
                             </div>;
        }
        
        if (profileJSON["walletAddress"] != undefined) {
            walletAddressDOM =  <div className = "padding_16_0">
                                    Wallet Address: { profileJSON["walletAddress"] }
                                </div>;
        }



        if (this.state.postLoadMsg) {
              postLoadMsg = <div className="center_this">
                                { this.state.postLoadMsg }
                            </div>
        }

        
        if ( profileJSON.userID == this.props.globalProps.currentUser.userID || this.props.globalProps.currentUser.https == "superuser" ) {
            profileActions = <div className="center_this">
            
                                <div className = "gray_box">
                                        Earn 500 TRD for each referral! &nbsp; <div className = "text_sm">(Legitimate referrals only.&nbsp; To prevent spamming, we reserve the right to limit your earnings.)</div><br />
                                        Your referral link:<br />https://www.tradesmantoken.com/?ref={ profileJSON.userID }<br /><br />
                                        Referrals to date: <span className = "pointer red bold"
                                                                 onClick   = { this.handleReferralsClick }
                                                            > { profileJSON["num_referrals"] }
                                                            </span>
                                </div>
                                                            
                                { referralsDOM }
                                        
                                <input  type            = "button" 
                                        className       = "button_sm inline_block_white margin_30_0_0" 
                                        onClick         = { this.handleEdit } 
                                        defaultValue    = "Edit profile"
                                />
                            </div>

        }
               

          return (
              <div className="container max_width_980">
                  <div className = "profile_images">
                              
                      <img    src       = {`${this.props.globalProps.GCS_IMAGE_PATH }user/${ profileJSON.bgImage }`}   
                              alt       = ''
                              className = "profile_bg"
                      />
                  
                      <img    src       = {`${this.props.globalProps.GCS_IMAGE_PATH }user/${ profileJSON.avatar }`}   
                              alt       = ''
                              className = "profile_avatar"
                      />
                                      
                      <h3 className = "slogan">
                          { profileJSON.slogan }
                      </h3>
                                      
                  </div>
                  { banUserDOM }

                          
                  <div className = "profile_details">
                      <h4>Description:</h4>
                      { descDOM } 
                  </div>
                      
				  <div style={{"textAlign":"left", "marginTop":"2rem"}}>
                      <h4>Contact:</h4>
                          { nameDOM }
                          { orgDOM }
                          { addressDOM }
                          { cityStateDOM }
                          { phoneDOM }
                          { emailDOM }
                          { urlDOM }
                          { twitDOM }
                          { fbDOM }
                          { instaDOM }	
                          { categoriesDOM }
                          { walletAddressDOM } 
                          { postLoadMsg } 					
                  </div>
	  
                  
                  { profileActions }

            </div>
          )            
      }  
  }
  
}


export default Profile;