require("../styles/_Common.scss");
require("../styles/_Forms.scss");

import CountriesStatesPulldown  from "./CountriesStatesPulldown.jsx";
import CategoriesChoices        from "./CategoriesChoices.jsx";
import React                    from "react";


class Search extends React.Component { 
    
    constructor(props) {
        super(props);
        
        this.state = {
            generalErrorText                : '',
            countryErrorText                : '',
            categoriesErrorText             : '',
            countrySelected                 : '-Select Country-',
            sendButtonText                  : 'Search',
            searchResults                   : []
        };

        this.handleSubmit                   = this.handleSubmit.bind(this);
        this.handleCountrySelect            = this.handleCountrySelect.bind(this);
        this.busyFetching                   = false;
    }
    
    componentDidMount() {

    }

    handleCountrySelect(e) {
        this.setState(
            {
                countrySelected    : this.refs.country.state.selectedValue
            }
        );
    }
    

    
    handleSubmit(e) {
        e.preventDefault();
        
        var country             = this.refs.country.state.selectedValue,
            city                = this.refs.city.value.trim(),
            state               = this.refs.State.state.selectedValue;
        
        var categories          = [];
        
        document.querySelectorAll('input[name=category_choices]:checked').forEach( function(ckbox) {
            categories.push( ckbox.value );
        });
        
        var allowSubmit = true;
        
        this.setState(
            {
                                countryErrorText    : '',
                                categoriesErrorText : '',
                                generalErrorText    : ''
            }
        );
        
        if ( categories.length == 0 ) {
            allowSubmit = false;
            this.setState(
                {
                                    categoriesErrorText : 'You must select at least one category, or "All"',
                                    generalErrorText    : "Submit error.  Please correct errors listed above."
                }
            );
        }
        
        if (country == "-Select Country-" || country == "--" || country == undefined) {
            allowSubmit = false;
            this.setState(
                {
                                    countryErrorText    : 'You must select a country, or "Worldwide" if none applies',
                                    generalErrorText    : "Submit error.  Please correct errors listed above."
                }
            )
        }
        

        if (allowSubmit) {
            this.busyFetching   = true; // don't allow again until thank you page or error
            this.setState(
                {
                    sendButtonText  : "Processing..."
                }
            );

            
            var formData = new FormData();


                formData.append("country",          country);
                
                formData.append("city",             city);
                formData.append("state",            state);
                formData.append("categories",       JSON.stringify(categories));
                
            var ajaxReq =             new XMLHttpRequest();
                ajaxReq.addEventListener(
                    "load", 
                    function() {
                        var responseJSON =          JSON.parse(ajaxReq.responseText);
                        console.log(responseJSON);
                        
                        if ( responseJSON["msg"] == "success" ) {
                            
                            // so can persist after user goes fwd then back
                            if ( localStorage ) {
                                localStorage.setItem('searchResults', JSON.stringify( responseJSON['queryJSON'] ));
                            }
                            
                            
                            this.setState( {
                                searchResults       : responseJSON['queryJSON'],
                                sendButtonText      : "Search again"
                            });

                            

                        } else {
                            // give user msg
                            this.setState( {
                                statusText          : 'edit error',
                                generalErrorText    : responseJSON["msg"],
                                sendButtonText      : "Search"
                            });
                        }
                        this.busyFetching = false;
                        return;
                   
                    }.bind(this), 
                    false
                );
                ajaxReq.addEventListener(
                    "error", 
                    function(xhr, status, err) {
                        console.error("/search", status, err.toString());
                        this.setState(
                            {
                                generalErrorText    : responseJSON,
                                sendButtonText      : "Search"
                            }
                        );
                        this.busyFetching = false;
                        return;
                    }.bind(this), 
                    false
                );
                ajaxReq.open( "post", "/search", true );
                ajaxReq.setRequestHeader("X-CSRF-Token", csrfToken);
                //ajaxReq.setRequestHeader("Content-type","application/x-www-form-urlencoded");
                ajaxReq.setRequestHeader('X-Requested-With','XMLHttpRequest');
                ajaxReq.send(formData);
        }
        return;
        
      }

    render() {
        
        if ( getIEVersion() < 10 ) {
            return (
                <div />
            )
        }

        var searchResultsText   = '';
        var searchResults       = [];
        
        if ( this.state.searchResults.length ) {
            searchResultsText   = "Search Results:";
            searchResults       = this.state.searchResults;
        } else if ( localStorage && JSON.parse(localStorage.getItem('searchResults')) ) {
            searchResultsText   = "Recent Search Results:";
            searchResults       = JSON.parse(localStorage.getItem('searchResults'));
        }        
        
        var searchResultsDOM    = (searchResults.length)
                                    ?   <div>
                                            <h2 className = "margin_10_0">
                                                { searchResultsText }
                                            </h2>
                                            <table className = "table table-striped">
                                                <tbody>
                                                { searchResults.map( function(result, index) {
                                                    return (
                                                        <tr key = {`tr_${ index}`} 
                                                            onClick = { function() {
                                                                    window.location.href = "/profile/" + result[0];
                                                                }
                                                            }
                                                            className = "pointer"
                                                        >
                                                            <td style={{"padding":"0px 4px"}}>
                                                                {result[1]}
                                                            </td>
                                                            <td style={{"padding":"0px 4px"}}>
                                                                {result[2]}
                                                            </td>
                                                            <td style={{"padding":"0px 4px"}}>
                                                                {result[3]}
                                                            </td>
                                                            <td style={{"padding":"0px 4px"}}>
                                                                {result[4]}
                                                            </td>
                                                            <td style={{"padding":"0px 4px"}}>
                                                                {result[5]}
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    :   null;
        
                        
        var generalErrorDOM     = (this.state.generalErrorText)
                                    ?   <div className="errors">
                                            { this.state.generalErrorText }
                                        </div>
                                    :   null;
                                    
        var countryErrorDOM     = (this.state.countryErrorText)
                                    ?   <div className="errors">
                                            { this.state.countryErrorText }
                                        </div>
                                    :   null;
                            
        var categoriesErrorDOM  = (this.state.categoriesErrorText)
                                    ?   <div className="errors">
                                            { this.state.categoriesErrorText }
                                        </div>
                                    :   null;
                                    
        return  (
            <div className = "container center_this">
                <br /><br />
                <h3>Search for merchants</h3>
            
                <form   ref     = "theForm" 
                        action  = "" 
                        method  = "post" 
                        encType = "multipart/form-data"
                > 
                    <input type="hidden" name="csrf_token" value={ csrfToken }/>
                    <div className = "margin_0_auto table_resp align_left">

                            <div className = "margin_10_0">
                                    country: { countryErrorDOM }<br/>
                                    <CountriesStatesPulldown
                                        ref             = "country"
                                        which           = "countries"
                                        onChangeHandler = { this.handleCountrySelect }
                                    />

                            </div>
                            <div className = "margin_10_0">
                                <div className = "form_td_sm inline_block">

                                        city: <br/>
                                        <input  ref             = "city" 
                                                type            = "text" 
                                                className       = "form_input ht_175" 
                                        />
                                </div>
                                <div className="form_td_sm inline_block">
                                    state: <br/>
                                    <CountriesStatesPulldown
                                        ref             = "State"
                                        which           = "states"
                                        country         = { this.state.countrySelected }
                                        selectedValue   = ''
                                    />

                                </div>
                            </div>
                            <div className = "margin_10_0">
                                    choose up to 5 categories: { categoriesErrorDOM }<br/>
                                    <CategoriesChoices checkedList = { [] } />

                            </div>

                    </div>
                </form>
                { generalErrorDOM }
                <input  type            = "button" 
                        ref             = "editButton" 
                        className       = "button_sm form_submit" 
                        onClick         = { this.handleSubmit } 
                        defaultValue    = { this.state.sendButtonText }
                />
                
                { searchResultsDOM }

            </div>
            
            
        );
    }
}

export default Search;
