require("../styles/_Common.scss");
require("../styles/_Forms.scss");

var React               = require("react");


class LoginForm extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            errorText:              ''
        };
        
        this.handleResetPassword    = this.handleResetPassword.bind(this);
        this.handleRegister         = this.handleRegister.bind(this);
        this.handleSubmit           = this.handleSubmit.bind(this);
    }
    
    handleResetPassword() {
        window.location.href = "/reset_password/"; 
    }
    
    handleRegister() {
        window.location.href = "/register"; 
    }

    handleSubmit(e) {
        e.preventDefault();

        var email       = this.refs.email.value;
        var password    = this.refs.password.value;

        var handleLogin = this.props.globalProps.handleLogin;
        
        if (!email || !password) {
            this.setState(
                {
                            errorText:      "Both email and password required"
                }
            )
            return;
        }
        
        fetch('/ajax_login/', {
            method    : 'POST',
            headers   : {
                'Accept'        : 'application/json',
                'Content-Type'  : 'application/json; charset=UTF-8',
                'X-CSRFToken'   : csrfToken
            },
            mode      : 'same-origin',
            body      : JSON.stringify(
                {
                    email       : email,
                    password    : password
                }
            )
        })
        .then(
            function(response) {
                if (response.status !== 200) {
                    console.log('/ajax_login/ Status Code: ' + response.status);
                    return;
                }

                response.json().then(function(responseJSON) {
                    //console.error(responseJSON)
                    if ( responseJSON["msg"] == "success" ) { 

                        handleLogin(
                            {
                                name    : responseJSON["currentUserName"],
                                avatar  : responseJSON["currentUserAvatar"],
                                https   : responseJSON["https"],
                            }
                        );

                        this.props.hideForm();
                        location.reload();
                        return;
                    } else {
                        this.setState( 
                            {
                                errorText: responseJSON["msg"]
                            }
                        );
                    }
                }.bind(this));
            }.bind(this)
        )
        .catch(function(err) {
            console.log('/ajax_login/ Fetch Error :-S', err);
            return;
        });
    }

    render() {
        var oauthClickHandler = this.oauthClickHandler;
        var errorDOM =          null;
        
        if (this.state.errorText.length > 0) {
            errorDOM =          <div className="errors">{ this.state.errorText }</div>
        }

        return  (
            <div className="loginForm" key={ this.props.transitionKey }>
                <div>
                    <form 
                        action="/" 
                        method="post" 
                        onSubmit={ this.handleSubmit }
                    >
                        <input  ref         = "csrf_token" 
                                type        = "hidden" 
                                defaultValue= { this.props.globalProps.csrfToken } 
                        />
                        <table className="loginTable">
                            <tbody>
                            <tr>
                        		<td align="left" className="text_sm lt_gray">
                                    email:<br />
                                    <input ref="email" className="form_input" type="text" autoFocus />
                                </td>
                        		<td align="left" className="text_sm lt_gray padding_left_6">
                                    password:<br />
                                    <input ref="password" className="form_input" type="password" />
                                </td>
                                <td align="left" className="text_sm lt_gray padding_left_6">
                                    <br />
                                    <div className="button_sm nav-link text_sm lt_gray padding_0_8 pointer" onClick={ this.handleSubmit }>login</div>
                                </td>
                        	</tr>
                            </tbody>
                        </table>
                            { errorDOM }
                        <div className="flex rem1">
                            <div className="nav-link text_sm padding0" onClick={ this.handleRegister }>register</div>
                            <div className="text_sm lt_gray">&nbsp;&bull;&nbsp;</div> 
                            <div className="nav-link text_sm padding0" onClick={ this.handleResetPassword }>reset password</div>
                            <div className="text_sm lt_gray">&nbsp;&bull;&nbsp;</div> 
                            <div className="nav-link text_sm padding0" onClick={ this.props.hideForm }>cancel</div> 
                        </div>
                    </form>
                </div>
           </div>
        );
    }
}


export default LoginForm;
