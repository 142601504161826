require("../styles/_Common.scss");

// done inline in base_admin.html to keep out of public webpack.js
//require("../../application/static/js/jquery.qrcode.min.js")
//require("../../application/static/js/qrcode.js")

import React from "react";

class BulkEmailForm extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
        };
    }
    
    
    render() {                          
                                    
        return (
            <div className="container center_this max_width_760">
                <h1><u>This page emails to everyone who has Registered!</u></h1> 
                <br /> 

                <form name="thisform" method="POST" action="">

                    <div>	 
                    	Subject:<br />
                     	<textarea 
                            wrap    = "virtual" 
                            cols    = "80" 
                            rows    = "3" 
                            name    = "subject" 
                        />

                    </div>
    
                    <div className="margin_50_0">	
                    	Body:<br />
                    	<textarea 
                            wrap    = "virtual" 
                            cols    = "80" 
                            rows    = "20" 
                            name    = "body" 
                        />
 	
                    </div>
    
                    <div className="margin_50_0">	
                    	<input value=" Send " type="submit" />
                    </div>
	
                </form>

            
            </div>
        )
    }
}


export default BulkEmailForm;
