require("../styles/_Common.scss");
require("../styles/_Forms.scss");

import LoginForm  from "./LoginForm.jsx";

import React from "react";


class Header extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            showLoginForm:          false,
            
        };
        
        this.handleLoginClick       = this.handleLoginClick.bind(this);
        this.handleLogoutClick      = this.handleLogoutClick.bind(this);
        this.hideForm               = this.hideForm.bind(this);
    }
    
    handleLoginClick() {
        this.setState(
            { 
                "showLoginForm":    true
            }
        );
    }
    
    handleLogoutClick() {
        // just send to /logout
        var ajaxReq =             new XMLHttpRequest();
        ajaxReq.addEventListener(
            "load", 
            function() {
                var responseJSON =          JSON.parse(ajaxReq.responseText);
                
                if (responseJSON["msg"] == "success") {
                    
                    this.props.globalProps.handleLogout();
                    this.hideForm();
                    location.reload();
                    
                } else {
                    console.error("failed to logout: " + responseJSON["msg"]);
                };
                return;

            }.bind(this), 
            false
        );
        ajaxReq.addEventListener(
            "error", 
            function(xhr, status, err) {
                console.error(url, status, err.toString());
            }.bind(this), 
            false
        );
        ajaxReq.open( "get", "/logout", true );
        ajaxReq.send();
    }
    

    hideForm() {
        this.setState(
            { 
                showLoginForm:  false
            }
        );
    }
    
    
    
  render() {
      var currentUser       = this.props.globalProps.currentUser;
      
      var smallAvatarURL    = this.props.globalProps.GCS_IMAGE_PATH + "user/" + currentUser.avatar.replace("_lg", "_sm")

      var loginDOM          = null;
      var logoutWarningDOM  = null;
      
      
      var rightSideDOM      = (currentUser.name == "anonymous")
                                  ? (this.state.showLoginForm)
                                      // not logged in, login link clicked, show login form
                                      ? <div className = "navbar-collapse" id="navbar-contents">
                                            <ul className = "navbar-nav ml-auto mt-2 mt-md-0">
                                                <li className = "nav-item">
                                                    <LoginForm 
                                                        globalProps = { this.props.globalProps }
                                                        key         = "login_form_main"
                                                        hideForm    = { this.hideForm }
                                                    />
                                                </li>
                                            </ul>
                                        </div>
                                      
                                      // not logged in, login link not yet clicked, don't show login form  
                                      : <div className = "collapse navbar-collapse rem_9" id="navbar-contents">
                                            <ul className = "navbar-nav ml-auto mt-2 mt-md-0">
                                                <li className = "nav-item">
                                                    <a className = "nav-link" href="/how-to-buy">How to buy</a>
                                                </li>
                                                <li className = "nav-item">
                                                    <a className = "nav-link" href="/search">Search for merchants</a>
                                                </li>
                                                <li className = "nav-item">
                                                    <a className = "nav-link" href="/register">Register</a>
                                                </li>
                                                <li className = "nav-item">
                                                      <a className = "nav-link" 
                                                          onClick = { this.handleLoginClick }>Login</a>
                                                </li>
                                                <li className = "nav-item">
                                                    <a href="https://twitter.com/tradesmantoken" target="_blank" className = "nav-link">
                                                        <i className = "fa fa-twitter" aria-hidden="true"></i>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                  
                                  // logged in
                                  : <div className = "collapse navbar-collapse rem_9" id="navbar-contents">
                                            <ul className = "navbar-nav ml-auto mt-2 mt-md-0">
                                                <li className = "nav-item">
                                                    <a className = "nav-link" href="/how-to-buy">How to buy</a>
                                                </li>
                                                <li className = "nav-item">
                                                    <a className = "nav-link" href="/search">Search for merchants</a>
                                                </li>
                                                <li className = "nav-item">
                                                    <a href="https://twitter.com/tradesmantoken" target="_blank" className = "nav-link">
                                                        <i className = "fa fa-twitter" aria-hidden="true"></i>
                                                    </a>
                                                </li>
                                                <li className = "nav-item align_right ml_6">
                                                    <span>
                                                        <img    src = { smallAvatarURL } 
                                                                alt = "" 
                                                                className = "avatarSm"
                                                        />
                                                        <span className = "half_gray">
                                                            {` Hello,`}<a  href={`/profile/${ currentUser.userID }`}
                                                                            className = "nav-link inline_block_white"
                                                                        >{`${currentUser.name.split()[0]}`}</a>
                                                        </span>
                                                    </span>
                                                </li>
                                                <li className = "nav-item">
                                                    <span   onClick     = { function() {this.handleLogoutClick(currentUser) }.bind(this)}
                                                            className   = "nav-link"
                                                      >
                                                          log out
                                                      </span> 
                                                </li>
                                            </ul>
                                        </div>


      return (
          <div>
          
              <nav id="navbar" className = "navbar fixed-top navbar-toggleable-md navbar-inverse bg-primary">
                <div className = "container">
                    <button className = "navbar-toggler navbar-toggler-right" type="button" data-toggle="collapse" data-target="#navbar-contents"
                        aria-controls="navbar-contents" aria-expanded="false" aria-label="Toggle navigation">
                        <span className = "navbar-toggler-icon"></span>
                    </button>
                    <a className = "navbar-brand" href="/">
                        <img src="/static/img/apple-icon-180x180.png" className = "navbar-icon" alt="Tradesman Token logo" />
                        &nbsp; Tradesman Token
                    </a>
                    
                    { rightSideDOM }
                    
                </div>
            </nav>

            <nav className = "navbar navbar-toggleable-md">
                <span className = "navbar-brand">&nbsp;</span>
                <div className = "collapse navbar-collapse">
                    <ul className = "navbar-nav ml-auto mt-2 mt-md-0">
                        <li className = "nav-item">
                            <span className = "nav-link">pulldown</span>
                        </li>
                    </ul>
                </div>
            </nav>
          
          
          </div>
      )
   }
}


export default Header;
