require("../styles/_Common.scss");

import React from "react";

class Media extends React.Component {
    
    constructor(props) {
        super(props);
    }
    
    render() {
        return (
            <div className = "container margin_30_0_0">
                <h1 className = "display-5 text-center">Tradesman decal artwork:</h1>
                <h3 className = "text-center">(Click to enlarge)</h3>
    
                <br />
    
                <a href="/static/img/Tradesman_logo_782x782.png" target="_blank">
                    <img src="/static/img/Tradesman_logo_782x782.png" className = "media_image" alt="" />
                </a>
                <br />
    
                <a href="/static/img/Tradesman_WELCOME_1980X790.png" target="_blank">
                    <img src="/static/img/Tradesman_WELCOME_1980X790.png" className = "media_image" alt="" />
                </a>
                <br />
    
                <a href="/static/img/Tradesman_accepted_here_1980x790.png" target="_blank">
                    <img src="/static/img/Tradesman_accepted_here_1980x790.png" className = "media_image" alt="" />
                </a>
                <br />
    
                <a href="/static/img/Tradesman_welcome_stencil_2140x790.png" target="_blank">
                    <img src="/static/img/Tradesman_welcome_stencil_2140x790.png" className = "media_image" alt="" />
                </a>
                <br />
        
                <a href="/static/img/Tradesman_got_1640x780.png" target="_blank">
                    <img src="/static/img/Tradesman_got_1640x780.png" className = "media_image" alt="" />
                </a>
            </div>
      )            
   }
}


export default Media;
