require("../styles/_Common.scss");

// done inline in base_admin.html to keep out of public webpack.js
//require("../../application/static/js/jquery.qrcode.min.js")
//require("../../application/static/js/qrcode.js")

import React from "react";

class ViewWallet extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
        };
    }
    
    componentDidMount() {
        
        var publicKey  = this.props.globalProps.varsJSON.publicKey;
        var privateKey = this.props.globalProps.varsJSON.privateKey;
    
    	$('#publicQR').qrcode( publicKey );
        $('#privateQR').qrcode( privateKey );
    }
    
    render() {    
        
        if (this.props.globalProps.varsJSON.msg != '') {
            return (
                <div className="container center_this max_width_760 margin_30_0_0">
                { this.props.globalProps.varsJSON.msg }
                </div>
            );
        }  
                               
                                    
        return (
            <div className="container center_this max_width_760">
                <h1><u>Store in a safe place!<br />If a person gets these keys, they can steal your tokens!</u></h1> 
                <br /> 
                <table className='container' className='border_none'> 
                    <tbody>
                        <tr> 
                            <td className = 'right_padded bold'> 
                                name: 
                            </td> 
                                <td className = 'left_padded'>{ this.props.globalProps.varsJSON.name }
                            </td> 
                        </tr> 
    
                        <tr> 
                            <td className = 'right_padded bold'> 
                                email: 
                            </td> 
                            <td className = 'left_padded'>{ this.props.globalProps.varsJSON.email }
                            </td> 
                        </tr> 
    
                        <tr> 
                            <td className = 'right_padded bold'> 
                                Address (public key): 
                            </td> 
                            <td className = 'left_padded'>{ this.props.globalProps.varsJSON.publicKey }
                            </td> 
                        </tr> 
    
                        <tr> 
                            <td className = 'right_padded bold'> 
                                private key (unencrypted): 
                            </td> 
                            <td className = 'left_padded'>{ this.props.globalProps.varsJSON.privateKey }
                            </td> 
                        </tr> 
    
                        <tr> 
                            <td className = 'right_padded bold'> 
                                password: 
                            </td> 
                                <td className = 'left_padded'> {`→${ this.props.globalProps.varsJSON.password }←`}
                            </td> 
                        </tr> 
                        <tr> 
                            <td className = 'right_padded bold'> 
                                keystore JSON: 
                            </td> 
                            <td className='keystore'>{ this.props.globalProps.varsJSON.keystoreJSON }
                            </td> 
                        </tr> 
                        <tr> 
                            <td className = 'right_padded bold'> 
                                original number of tokens: 
                            </td> 
                            <td className = 'left_padded'>{ this.props.globalProps.varsJSON.origNumTokens }</td> 
                        </tr> 
                        <tr> 
                            <td className = 'right_padded bold'> 
                                Tradesman (TRD) contract address: 
                            </td> 
                            <td className = 'left_padded'>0xE9e03497fBF8f9463b2124fe2c8CeB8C3C92d8CD</td> 
                        </tr> 
                        <tr> 
                            <td className='padding_20'> 
                                Public Key: <br /><br /> 
                                <div id='publicQR'></div> 
                            </td> 
                            <td className='padding_20'> 
                                Private Key (unencrypted): <br /><br /> 
                                <div id='privateQR'></div> 
                            </td> 
                        </tr> 
                                
                        <tr> 
                            <td className = 'right_padded bold'> 
                                Date created: 
                            </td> 
                            <td className = 'left_padded'>{ this.props.globalProps.varsJSON.dateCreated }</td> 
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
}


export default ViewWallet;
