require("../styles/_Common.scss");

import React from "react";

class CategoriesChoices extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
        };
        
        // also change in views_auth.py
        this.choices =  [
            [0, "All"], 
            [10, "Accounting"], 
            [20, "Advertising"], 
            [30, "Agriculture"], 
            [40, "Animals & Pets"], 
            [50, "Art & Antiques"], 
            [60, "Automotive"], 
            [70, "Beauty Products"], 
            [80, "Business Products"], 
            [90, "Charities"], 
            [100, "Clothing"], 
            [110, "Computer - Hardware & Services"], 
            [120, "Computer - Software, Web, Graphic Arts"],
            [130, "Consulting"], 
            [140, "Construction"], 
            [150, "Education"], 
            [160, "Entertainment"], 
            [170, "Finance & Investing"],
            [180, "Food & Beverage"], 
            [190, "Furniture & Accessories"], 
            [200, "Health, Fitness, & Medical"], 
            [210, "Home & Garden"], 
            [220, "Industrial & Manufacturing"], 
            [230, "Jewelry"], 
            [240, "Legal Services"], 
            [250, "Marine & Aviation"], 
            [260, "Personal Care & Services"], 
            [270, "Photography"], 
            [280, "Printing & Stationery"], 
            [290, "Real Estate"], 
            [300, "Repairs & Maintenance"], 
            [310, "Retail & Shopping"], 
            [320, "Safety & Security"], 
            [330, "Sports & Recreation"], 
            [340, "Telecommunications"], 
            [350, "Transportation & Delivery"], 
            [360, "Travel"], 
            [370, "Other"]
        ];
        
        this.doNothing = this.doNothing.bind(this);
    }
    
    componentDidMount() {
        
        this.props.checkedList.map(
            function (item) {
                this.refs["choices_" + item].checked = true;
            }.bind(this)
        );
    } 
    
    doNothing() {
        var q;
    }
    
    render() {
        // onChange    = { this.doNothing }
        
        var column1 =    this.choices.map(
            function (item) {
                if (item[0] <= 180) {
                    return (
                        <div key = { `div_` + item[0] }>
                            <label key = { `label_` + item[0] }>
                                <input  type        = "checkbox" 
                                        name        = "category_choices" 
                                        value       = { item[0] }
                                        key         = { `choices_` + item[0] }
                                        ref         = { `choices_` + item[0] }
                                 />
                                &nbsp;{ item[1] }
                            </label>
                        </div>
                    )
                }
                
            }.bind(this)
        );
        
        var column2 =    this.choices.map(
            function (item) {
                if (item[0] > 180) {
                    return (
                        <div key = { `div_` + item[0] }>
                            <label key = { `label_` + item[0] }>
                                <input  type        = "checkbox" 
                                        name        = "category_choices" 
                                        value       = { item[0] }
                                        key         = { `choices_` + item[0] }  
                                        ref         = { `choices_` + item[0] }
                                 />
                                &nbsp;{ item[1] }
                            </label>
                        </div>
                    )
                }
                
            }.bind(this)
        );
       
                                
        return (
            <div className="margin_10_0">

                <div className = "inline_block_align_top margin_0_30_0_0">
                    { column1 }
                </div>
                <div className = "inline_block_align_top">
                    { column2 }
                </div>

            </div>
        )            
    }
}


export default CategoriesChoices;
