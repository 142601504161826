require("../styles/_Common.scss");

// done inline in base_admin.html to keep out of public webpack.js
//require("../../application/static/js/jquery.qrcode.min.js")
//require("../../application/static/js/qrcode.js")

import React from "react";

class EditWallet extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            sendButtonText          : 'Send'
        };
          
        this.busyFetching   = false;
        this.handleSubmit   = this.handleSubmit.bind(this);
    }
    
    componentDidMount() {
        var publicKey  = this.props.globalProps.varsJSON.publicKey;
        var privateKey = this.props.globalProps.varsJSON.privateKey;
    
    	$('#publicQR').qrcode( publicKey );
        $('#privateQR').qrcode( privateKey );
    }

    
    handleSubmit() {
        var allowSubmit     = true;
        if (this.busyFetching) {
            return;
        }
        
        var name            = this.refs.name.value,
            email           = this.refs.email.value,
            origNumTokens   = this.refs.origNumTokens.value;

    
        if (allowSubmit) {
            this.busyFetching  = true; // don't allow again until thank you page or error

            this.setState( {
                sendButtonText      : 'Processing...'
            });

            
            var formData = new FormData();
                formData.append("csrf_token",       this.refs.csrf_token.value);
                formData.append("name",             this.refs.name.value.trim());
                formData.append("email",            this.refs.email.value.trim());
                formData.append("origNumTokens",    this.refs.origNumTokens.value.trim());

            var ajaxReq =             new XMLHttpRequest();

                ajaxReq.addEventListener(
                    "load", 
                    function() {
                        this.busyFetching  = false;
                        // wait for db to change
                        setTimeout(function () {
                            location.href = location.href.replace('/edit_wallet', '/view_wallet');
                        }, 2000);
                        return;
                    }.bind(this), 
                    false
                );
                ajaxReq.addEventListener(
                    "error", 
                    function(xhr, status, err) {
                        console.error("/trdadmin/edit_wallet/", status, err.toString());
                        this.setState( {
                            successfulPost      : false,
                            sendButtonText      : 'Send'
                        });
                        this.busyFetching = false;
                        return;
                    }.bind(this), 
                    false
                );
                ajaxReq.open( "post", window.location.pathname, true );
                ajaxReq.setRequestHeader("X-CSRF-Token", csrfToken);
                ajaxReq.setRequestHeader('X-Requested-With','XMLHttpRequest');
                ajaxReq.send(formData);
        }
    }

    
    render() {    
        
        if (this.props.globalProps.varsJSON.msg != '') {
            return (
                <div className="container center_this max_width_760 margin_30_0_0">
                { this.props.globalProps.varsJSON.msg }
                </div>
            );
        } 
                                                 
        return (
            <div className="container center_this max_width_760">
                <h1><u>Store in a safe place!<br />If a person gets these keys, they can steal your tokens!</u></h1> 
                <br /> 
                <form action="" method="post" encType="multipart/form-data"> 
                    <input  ref         = "csrf_token" 
                            type        = "hidden" 
                            defaultValue= { this.props.globalProps.csrfToken } 
                    />
                    <table className='container' className='border_none'> 
                        <tbody>
                            <tr> 
                                <td className = 'right_padded bold'> 
                                    name: 
                                </td> 
                                <td className = 'left_padded'>
                                    <input ref="name" type="text" className="form_input ht_175" defaultValue={ this.props.globalProps.varsJSON.name } />
                                </td> 
                            </tr> 

                            <tr> 
                                <td className = 'right_padded bold'> 
                                    email: 
                                </td> 
                                <td className = 'left_padded'>
                                    <input ref="email" type="text" className="form_input ht_175" defaultValue={ this.props.globalProps.varsJSON.email } />
                                </td> 
                            </tr> 

                            <tr> 
                                <td className = 'right_padded bold'> 
                                    Address (public key): 
                                </td> 
                                <td className = 'left_padded'>{ this.props.globalProps.varsJSON.publicKey }
                                </td> 
                            </tr> 

                            <tr> 
                                <td className = 'right_padded bold'> 
                                    private key (unencrypted): 
                                </td> 
                                <td className = 'left_padded'>{ this.props.globalProps.varsJSON.privateKey }
                                </td> 
                            </tr> 

                            <tr> 
                                <td className = 'right_padded bold'> 
                                    password: 
                                </td> 
                                    <td className = 'left_padded'> {`→${ this.props.globalProps.varsJSON.password }←`}
                                </td> 
                            </tr> 
                            <tr> 
                                <td className = 'right_padded bold'> 
                                    keystore JSON: 
                                </td> 
                                <td className='keystore'>{ this.props.globalProps.varsJSON.keystoreJSON }
                                </td> 
                            </tr> 
                            <tr> 
                                <td className = 'right_padded bold'> 
                                    original number of tokens: 
                                </td> 
                                <td className = 'left_padded'>
                                    <input ref="origNumTokens" type="text" defaultValue={ this.props.globalProps.varsJSON.origNumTokens } />
                                </td> 
                            </tr> 
                            <tr> 
                                <td className = 'right_padded bold'> 
                                    Tradesman (TRD) contract address: 
                                </td> 
                                <td className = 'left_padded'>0xE9e03497fBF8f9463b2124fe2c8CeB8C3C92d8CD</td> 
                            </tr> 
                            <tr> 
                                <td className='padding_20'> 
                                    Public Key: <br /><br /> 
                                    <div id='publicQR'></div> 
                                </td> 
                                <td className='padding_20'> 
                                    Private Key (unencrypted): <br /><br /> 
                                    <div id='privateQR'></div> 
                                </td> 
                            </tr> 
                        </tbody>
                    </table>
                </form>
                <input type="button" id="sendButton" className="button_sm form_submit" onClick= { this.handleSubmit } value={ this.state.sendButtonText } />
            </div>
        )
    }
}


export default EditWallet;
