require("../styles/_Common.scss");
require("../styles/_Forms.scss");

import CountriesStatesPulldown  from "./CountriesStatesPulldown.jsx";
import CategoriesChoices        from "./CategoriesChoices.jsx";
import React                    from "react";


class ProfileForm extends React.Component { 
    
    constructor(props) {
        super(props);
        
        this.profileJSON = this.props.globalProps.varsJSON.profileJSON;
        
        this.state = {
            avImageErrorText                : '',
            bgImageErrorText                : '',
            generalErrorText                : '',
            nameErrorText                   : '',
            emailErrorText                  : '',
            statusText                      : '',
            countrySelected                 : this.profileJSON.country,
            descValue                       : this.profileJSON.desc,
            sendButtonText                  : "Update, then view public profile",
            avatarLabelHtml                 : "orig",
            bgImageLabelHtml                : "orig",
            avUploadBoxClassList            : ["uploadBox", "box__input", "center_this"],
            bgUploadBoxClassList            : ["uploadBox", "box__input", "center_this"]
        };

        this.handleSubmit                   = this.handleSubmit.bind(this);
        this.handleCountrySelect            = this.handleCountrySelect.bind(this);
        this.handleDescChange               = this.handleDescChange.bind(this);
        this.showAvFiles                    = this.showAvFiles.bind(this);
        this.showBgFiles                    = this.showBgFiles.bind(this);
        this.addAvatarUploadBoxClass        = this.addAvatarUploadBoxClass.bind(this);
        this.addBgImageUploadBoxClass       = this.addBgImageUploadBoxClass.bind(this);
        this.removeAvatarUploadBoxClass     = this.removeAvatarUploadBoxClass.bind(this);
        this.removeBgImageUploadBoxClass    = this.removeBgImageUploadBoxClass.bind(this);
        this.busyFetching                   = false;
        this.avFiles                        = null;
        this.bgFiles                        = null;
    }
    
    componentDidMount() {
        
        if ( this.profileJSON.msg != 'show profile' ) return;   // has error msg
        
        if ( this.profileJSON.hideName ) {
            this.refs.hideName.checked = true;
        }
        if ( this.profileJSON.hideAddress ) {
            this.refs.hideAddress.checked = true;
        }
        if ( this.profileJSON.hideEmail ) {
            this.refs.hideEmail.checked = true;
        }
        if ( this.profileJSON.hidePhone ) {
            this.refs.hidePhone.checked = true;
        }
        if ( this.profileJSON.hideWallet ) {
            this.refs.hideWallet.checked = true;
        }
        
        this.avUploadBox        = this.refs.avUploadBox;
        this.bgUploadBox        = this.refs.bgUploadBox;
        this.form               = this.refs.theForm;

        var fileInputs          = this.form.querySelectorAll( 'input[type="file"]' );
        this.avFileInput        = fileInputs[0];
        this.bgFileInput        = fileInputs[1];
        //this.errorMsg         = this.form.querySelector( '.box__error span' );
        //this.restart          = this.form.querySelectorAll( '.box__restart' );
        
        var fakeDiv             = document.createElement('div');
        var hasAdvancedUpload   = ('draggable' in fakeDiv) || ('ondragstart' in fakeDiv && 'ondrop' in fakeDiv);
            hasAdvancedUpload   = hasAdvancedUpload && ('FormData' in window);
            hasAdvancedUpload   = hasAdvancedUpload && ('FileReader' in window);
            
            
        if (hasAdvancedUpload) {
            this.addAvatarUploadBoxClass('has-advanced-upload');
            this.addBgImageUploadBoxClass('has-advanced-upload');

            ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach( function(e) {
                this.avUploadBox.addEventListener(e, function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                }.bind(this));
                this.bgUploadBox.addEventListener(e, function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                }.bind(this));
            }.bind(this));
            ['dragover', 'dragenter'].forEach( function(e) {
                this.avUploadBox.addEventListener(e, function() {
                    this.addAvatarUploadBoxClass('is-dragover');
                }.bind(this));
                this.bgUploadBox.addEventListener(e, function() {
                    this.addBgImageUploadBoxClass('is-dragover');
                }.bind(this));
            }.bind(this));
            ['dragleave', 'dragend', 'drop'].forEach( function(e) {
                this.avUploadBox.addEventListener(e, function() {
                    this.removeAvatarUploadBoxClass('is-dragover');
                }.bind(this));
                this.bgUploadBox.addEventListener(e, function() {
                    this.removeBgImageUploadBoxClass('is-dragover');
                }.bind(this));
            }.bind(this));

            this.avUploadBox.addEventListener('drop', function(e) {
                if (e.originalEvent) {
                    this.avFiles = e.originalEvent.dataTransfer.files;
                } else {
                    this.avFiles = e.dataTransfer.files;
                }
                this.showAvFiles( this.avFiles );
            }.bind(this));
            this.bgUploadBox.addEventListener('drop', function(e) {
                if (e.originalEvent) {
                    this.bgFiles = e.originalEvent.dataTransfer.files;
                } else {
                    this.bgFiles = e.dataTransfer.files;
                }
                this.showBgFiles( this.bgFiles );
            }.bind(this));  

        }
        this.avFileInput.addEventListener('change', function(e) {
            if(!e.target.files || !window.FileReader) return;
            this.avFiles = e.target.files;
            this.showAvFiles( this.avFiles );
        }.bind(this));
        this.bgFileInput.addEventListener('change', function(e) {
            if(!e.target.files || !window.FileReader) return;
            this.bgFiles = e.target.files;
            this.showBgFiles( this.bgFiles );
        }.bind(this));  
    } 
    
    
    
    addAvatarUploadBoxClass(className) {
        var upload_box_class_list = this.state.avUploadBoxClassList;
        if ( upload_box_class_list.indexOf(className) == -1 ) { // if not already there
            upload_box_class_list.push(className)
            this.setState(
                {
                    avUploadBoxClassList : upload_box_class_list
                }
            );
        }
    }
    
    addBgImageUploadBoxClass(className) {
        var upload_box_class_list = this.state.bgUploadBoxClassList;
        if ( upload_box_class_list.indexOf(className) == -1 ) { // if not already there
            upload_box_class_list.push(className)
            this.setState(
                {
                    bgUploadBoxClassList : upload_box_class_list
                }
            );
        }
    }

    removeAvatarUploadBoxClass(className) {
        var upload_box_class_list = this.state.avUploadBoxClassList;
        
        var index = upload_box_class_list.indexOf(className);
        
        if (index !== -1) {                         // if it's there
            upload_box_class_list.splice(index, 1);
            this.setState(
                {
                    avUploadBoxClassList : upload_box_class_list
                }
            );
        } 
    }
    
    removeBgImageUploadBoxClass(className) {
        var upload_box_class_list = this.state.bgUploadBoxClassList;
        
        var index = upload_box_class_list.indexOf(className);
        
        if (index !== -1) {                         // if it's there
            upload_box_class_list.splice(index, 1);
            this.setState(
                {
                    bgUploadBoxClassList : upload_box_class_list
                }
            );
        } 
    }
    
    handleCountrySelect(e) {
        this.setState(
            {
                countrySelected    : this.refs.country.state.selectedValue
            }
        )
    }
    
    handleDescChange(e) {
        this.setState(
            {
                descValue           : e.target.value
            }
        );
    }
    
    showAvFiles(files) {
        var text = (files.length > 1) 
                        ? files.length + " files selected"
                        : files[ 0 ].name;
        this.setState(
            {
                avatarLabelHtml         : text
            }
        );
    }
    
    showBgFiles(files) {
        var text = (files.length > 1) 
                        ? files.length + " files selected"
                        : files[ 0 ].name;
        this.setState(
            {
                bgImageLabelHtml        : text
            }
        );
    }
    
    handleSubmit(e) {
        e.preventDefault();
        
        var fullName        = this.refs.fullName.value.trim(),
            hideName        = this.refs.hideName.checked,
            org             = this.refs.org.value.trim(),
            country         = this.refs.country.state.selectedValue,
            address         = this.refs.address.value.trim(),
            hideAddress     = this.refs.hideAddress.checked,
            city            = this.refs.city.value.trim(),
            state           = this.refs.State.state.selectedValue,
            email           = this.refs.email.value.trim(),
            hideEmail       = this.refs.hideEmail.checked,
            phone           = this.refs.phone.value.trim(),
            hidePhone       = this.refs.hidePhone.checked,
            url             = this.refs.url.value.trim(),
            twitterUrl      = this.refs.twitterUrl.value.trim(),
            facebookUrl     = this.refs.facebookUrl.value.trim(),
            instagramUrl    = this.refs.instagramUrl.value.trim(),
            walletAddress   = this.refs.walletAddress.value.trim(),
            hideWallet      = this.refs.hideWallet.checked,
            slogan          = this.refs.slogan.value.substring(0, 500),
            desc            = this.state.descValue;
            
            if (url != '' && !url.startsWith('http')) {
                url = 'http://' + url;
            }
            
            if (twitterUrl != '' && !twitterUrl.startsWith('http') && !twitterUrl.startsWith('https')) {
                twitterUrl = 'https://' + twitterUrl;
            }
            
            if (facebookUrl != '' && !facebookUrl.startsWith('http') && !facebookUrl.startsWith('https')) {
                facebookUrl = 'https://' + facebookUrl;
            }
            
            if (instagramUrl != '' && !instagramUrl.startsWith('http') && !instagramUrl.startsWith('https')) {
                instagramUrl = 'https://' + instagramUrl;
            }
            
        var categories          = [];
        document.querySelectorAll('input[name=category_choices]:checked').forEach( function(ckbox) {
            categories.push( ckbox.value );
        });
        
        this.setState(
            {
                generalErrorText:       '',
                nameErrorText:          '',
                emailErrorText:         '',
                avImageErrorText:      '',
                bgImageErrorText:      ''
            }
        )
        if (this.busyFetching) {
            return;
        }
        
        var allowSubmit = true;
        
        if (fullName.replace(/\s/g, '') == '') {
            allowSubmit = false;
            this.setState(
                {
                    nameErrorText       : "Name required.  Use the 'hide' feature if you do not want it made public. →",
                    generalErrorText    : "Submit error.  Please correct errors listed above."
                }
            )
        }
        
        if (email.replace(/\s/g, '') == '') {
            allowSubmit = false;
            this.setState(
                {
                    emailErrorText      : "Email address required.",
                    generalErrorText    : "Submit error.  Please correct errors listed above."
                }
            )
        } else {
            var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\"\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(email)) {
                allowSubmit = false;
                this.setState(
                    {
                        emailErrorText      : "Invalid email format.",
                        generalErrorText    : "Submit error.  Please correct errors listed above."
                    }
                )
            }
        }

        
        if (this.avFiles) {
            var avatar              = this.avFiles[0];
            if (
                    ( ['image/png', 'image/jpeg', 'image/gif'].indexOf(avatar.type) == -1 ) ||
                    ( ['.png', 'jpeg', '.jpg', '.gif'].indexOf(avatar.name.slice(-4)) == -1 )
            ) {
                this.setState(
                    {
                        avImageErrorText    : "Only images are allowed.  Must be jpg, png, or gif.  " + avatar.name + "  has mimetype: " + avatar.type + ".",
                        avatarLabelHtml     : "orig",
                        generalErrorText    : "Submit error.  Please correct errors listed above."
                    }
                );
                allowSubmit     = false;
                this.avFiles    = null;
            }
        }
        
        if (this.bgFiles) {
            var bgImage              = this.bgFiles[0];
            if (
                    ( ['image/png', 'image/jpeg', 'image/gif'].indexOf(bgImage.type) == -1 ) ||
                    ( ['.png', 'jpeg', '.jpg', '.gif'].indexOf(bgImage.name.slice(-4)) == -1 )
            ) {
                this.setState(
                    {
                        bgImageErrorText    : "Only images are allowed.  Must be jpg, png, or gif.  " + bgImage.name + "  has mimetype: " + bgImage.type + ".",
                        bgImageLabelHtml    : "orig",
                        generalErrorText    : "Submit error.  Please correct errors listed above."
                    }
                );
                allowSubmit     = false;
                this.bgFiles    = null;
            }
        }
        
        
        if (allowSubmit) {
            this.busyFetching   = true; // don't allow again until thank you page or error
            this.setState(
                {
                    sendButtonText  : "Processing..."
                }
            );

            
            var formData = new FormData();

                formData.append("userID",           this.props.globalProps.currentUser.userID);
                formData.append("name",             fullName);
                formData.append("hideName",         hideName);
                formData.append("org",              org);
                formData.append("country",          country);
                formData.append("address",          address);
                formData.append("hideAddress",      hideAddress);
                formData.append("city",             city);
                formData.append("state",            state);
                formData.append("email",            email);
                formData.append("hideEmail",        hideEmail);
                formData.append("phone",            phone);
                formData.append("hidePhone",        hidePhone);
                formData.append("url",              url);
                formData.append("twitterUrl",       twitterUrl);
                formData.append("facebookUrl",      facebookUrl);
                formData.append("instagramUrl",     instagramUrl);
                formData.append("walletAddress",    walletAddress);
                formData.append("hideWallet",       hideWallet);
                formData.append("slogan",           slogan);
                formData.append("categories",       JSON.stringify(categories));
                formData.append("desc",             desc);
			    formData.append("avatar",           avatar);
                formData.append("bgImage",          bgImage);

            var ajaxReq =             new XMLHttpRequest();
                ajaxReq.upload.addEventListener(
                    "progress", 
                    function() {
                        this.setState(
                            {
                                bgImageLabelHtml     : 'busy'
                            }
                        );
                    }.bind(this), 
                    false
                );
                ajaxReq.addEventListener(
                    "load", 
                    function() {
                        var responseJSON =          JSON.parse(ajaxReq.responseText);
                        
                        if ( responseJSON["msg"] == "success" ) {
                            this.removeAvatarUploadBoxClass('is-uploading');
                            this.removeBgImageUploadBoxClass('is-uploading');

                            this.setState(
                                {
                                    statusText     : 'editing complete'
                                }
                            )
                            // wait a couple seconds for db consistency
                            setTimeout( function(){ 
                                window.location.href = "/profile/" + this.props.globalProps.currentUser.userID; 
                            }.bind(this), 3000);

                        } else {
                            // give user msg
                            this.setState( {
                                statusText          : 'edit error',
                                generalErrorText    : responseJSON["msg"],
                                avatarLabelHtml     : 'orig',
                                bgImageLabelHtml    : 'orig',
                                sendButtonText      : "Update, then view public profile"
                            });
                        }
                        this.busyFetching = false;
                        return;
                   
                    }.bind(this), 
                    false
                );
                ajaxReq.addEventListener(
                    "error", 
                    function(xhr, status, err) {
                        console.error("/profile_edit_process/", status, err.toString());
                        this.removeAvatarUploadBoxClass('is-uploading');
                        this.removeBgImageUploadBoxClass('is-uploading');
                        this.setState(
                            {
                                generalErrorText    : responseJSON,
                                avatarLabelHtml     : 'orig',
                                bgImageLabelHtml    : 'orig',
                                sendButtonText      : "Update, then view public profile"
                            }
                        );
                        this.busyFetching = false;
                        return;
                    }.bind(this), 
                    false
                );
                ajaxReq.open( "post", "/profile_edit_process/", true );
                ajaxReq.setRequestHeader("X-CSRF-Token", csrfToken);
                //ajaxReq.setRequestHeader("Content-type","application/x-www-form-urlencoded");
                ajaxReq.setRequestHeader('X-Requested-With','XMLHttpRequest');
                ajaxReq.send(formData);
        }
        return;
        
      }

    render() {
        
        if ( this.profileJSON.msg == 'User not found' || this.profileJSON.msg == 'Please log in to edit your profile' ) {
            return (
                <div className = "container center_this">
                    <br /><br /><br /><br />
                    { this.profileJSON.msg }
                </div>
            );
        }
        
        if ( this.state.statusText == 'update complete' ) {
            return (
                <div className = "container center_this">
                    <br /><br /><br /><br />
                    Activation required.  Please check your email for instructions.
                </div>
            );
        }

        var imageErrorDOM       = (this.state.avImageErrorText)
                                    ?   <div className="errors">
                                            { this.state.avImageErrorText }
                                        </div>
                                    :   null;
        
        var BGimageErrorDOM     = (this.state.bgImageErrorText)
                                    ?   <div className="errors">
                                            { this.state.bgImageErrorText }
                                        </div>
                                    :   null;
                        
        var generalErrorDOM     = (this.state.generalErrorText)
                                    ?   <div className="errors">
                                            { this.state.generalErrorText }
                                        </div>
                                    :   null;
       
        var nameErrorDOM        = (this.state.nameErrorText)
                                    ?   <div className="errors">
                                            { this.state.nameErrorText }
                                        </div>
                                    :   null; 

        var emailErrorDOM       = (this.state.emailErrorText)
                                    ?   <div className="errors">
                                            { this.state.emailErrorText }
                                        </div>
                                    :   null;
                            
        var avatar_label_inner_html     = this.state.avatarLabelHtml,
            bg_image_label_inner_html   = this.state.bgImageLabelHtml;                  
    
            switch(this.state.avatarLabelHtml) {
                case "orig":
                    avatar_label_inner_html =   <div>
                                                    <div className = "button_sm inline_block_white">
                                                        Choose file 
                                                    </div>
                                                    <span className = "box__dragndrop"> &nbsp;&nbsp;or drag it here</span>
                                                </div>
                    break;
                //case "busy":
                //avatar_label_inner_html =  <div>
                //                       <img src="/static/img/waiting.gif" className = "busy_gif" alt="" />
                //                    </div>
                //break;
                default:
                    avatar_label_inner_html = this.state.avatarLabelHtml;
            }
            
            switch(this.state.bgImageLabelHtml) {
                case "orig":
                    bg_image_label_inner_html = <div>
                                                    <div className = "button_sm inline_block_white">
                                                        Choose file 
                                                    </div>
                                                    <span className = "box__dragndrop"> &nbsp;&nbsp;or drag it here</span>
                                                </div>
                    break;
                case "busy":
                    bg_image_label_inner_html =  <div>
                                                      <img src="/static/img/waiting.gif" className = "busy_gif" alt="" />
                                                 </div>
                break;
                default:
                    bg_image_label_inner_html = this.state.bgImageLabelHtml;
            }
        
        var csrf_token = csrfToken
        
        var banUserDOM = null;
        if (this.props.globalProps.currentUser.https == "superuser") {
            banUserDOM = <div style={{"textAlign":"center","padding":"25px"}}>
                            <a href={`/trdadmin/ban_user/${ this.props.globalProps.currentUser.userID }`}>ban this user</a><br />
                         </div>;
        }

        return  (
            <div className = "container center_this">
                <br /><br />
                <h3>Update your profile.</h3><br />
                <div className="profile_explain">(Note: Fields marked with an asterisk(*) are mandatory.  If you wish to hide a field from public view, check its "hide" option below.)</div>
            
            { banUserDOM }
                <form   ref     = "theForm" 
                        action  = "" 
                        method  = "post" 
                        encType = "multipart/form-data"
                > 
                    <input type="hidden" name="csrf_token" value={ csrf_token }/>
                    <div className = "margin_0_auto table_resp align_left">
                        <div className="form_td">
                            <div className ="inline_block">
                                name*: { nameErrorDOM }
                            </div>
                            <div className="form_hide">
                                <label className="text_sm">
                                    <input  ref     = "hideName" 
                                            type    = "checkbox" 
                                    /> hide
                                </label>
                            </div><br />
                            <input  ref="fullName" 
                                    type="text" 
                                    className = "form_input ht_175" 
                                    defaultValue = { this.profileJSON.name }
                            />
                            
                        </div>

                        <div className="form_td">
                            organization: <br/>
                            <input  ref="org" 
                                    type="text" 
                                    className = "form_input ht_175" 
                                    defaultValue = { this.profileJSON.org }
                            />
                        </div>

                        <div className="form_td">
                            country: <br/>
                            <CountriesStatesPulldown
                                ref             = "country"
                                which           = "countries"
                                onChangeHandler = { this.handleCountrySelect }
                                selectedValue   = { this.profileJSON.country }
                            />
                        </div>

                        <div className="form_td">
                            address: <div className="form_hide">
                                <label className="text_sm">
                                    <input  ref     = "hideAddress" 
                                            type    = "checkbox"
                                    /> hide
                                </label>
                            </div><br/>
                            <input  ref="address" 
                                    type="text" 
                                    className = "form_input ht_175" 
                                    defaultValue = { this.profileJSON.address }
                            />
                        </div>
                        <div className="form_td_sm inline_block">
                            city: <br/>
                            <input  ref             = "city" 
                                    type            = "text" 
                                    className       = "form_input_sm ht_175" 
                                    defaultValue    = { this.profileJSON.city }
                            />
                        </div>
                        <div className="form_td_sm inline_block">
                            state: <br/>
                            <CountriesStatesPulldown
                                ref             = "State"
                                which           = "states"
                                country         = { this.state.countrySelected }
                                selectedValue   = { this.profileJSON.state }
                            />
                        </div>

                        <div className="form_td_sm inline_block">
                            email*: { emailErrorDOM }
                            <div className="form_hide">
                                <label className="text_sm">
                                    <input  ref     = "hideEmail" 
                                            type    = "checkbox" 
                                    /> hide
                                </label>
                            </div><br/>
                            <input  ref="email" 
                                    type="text" 
                                    className = "form_input_sm ht_175" 
                                    defaultValue = { this.profileJSON.email }
                            />
                        </div>
                        <div className="form_td_sm inline_block"> 
                            phone: 
                            <div className="form_hide">
                                <label className="text_sm">
                                    <input  ref     = "hidePhone" 
                                            type    = "checkbox" 
                                    /> hide
                                </label>
                            </div><br/>
                            <input  ref="phone" 
                                    type="text" 
                                    className = "form_input_sm ht_175" 
                                    defaultValue = { this.profileJSON.phone }
                            />
                        </div>

                        <div className="form_td">
                                    password: &nbsp; 
                            <a href="/reset_password/">reset password</a>
                        </div> 

                        <div className="form_td">
                            website url: <br/>
                            <input  ref             = "url" 
                                    type            = "text" 
                                    className       = "form_input ht_175" 
                                    defaultValue    = { this.profileJSON.url }
                                    placeholder     = "http://www.example.com"
                            />
                        </div>
                                    
                        <div className="form_td">
                            twitter url: <br/>
                            <input  ref             = "twitterUrl" 
                                    type            = "text" 
                                    className       = "form_input ht_175" 
                                    defaultValue    = { this.profileJSON.twitterUrl }
                                    placeholder     = "https://twitter.com/@yourhandle"
                            />
                        </div>
                                    
                        <div className="form_td">
                            facebook url: <br/>
                            <input  ref             = "facebookUrl" 
                                    type            = "text" 
                                    className       = "form_input ht_175" 
                                    defaultValue    = { this.profileJSON.facebookUrl }
                                    placeholder     = "https://www.facebook.com/your_name"
                            />
                        </div>
                                    
                        <div className="form_td">
                            instagram url: <br/>
                            <input  ref             = "instagramUrl" 
                                    type            = "text" 
                                    className       = "form_input ht_175" 
                                    defaultValue    = { this.profileJSON.instagramUrl }
                                    placeholder     = "https://www.instagram.com/your_name/"
                            />
                        </div>

                        <div className="form_td">
                            TRD wallet address: 
                            <div className="form_hide_2">
                                <label className="text_sm">
                                    <input  ref     = "hideWallet" 
                                            type    = "checkbox" 
                                    /> hide
                                </label>
                            </div><br/>
                                    <div className = "text_sm">
                                        (IMPORTANT: Make sure you have a token-compatible wallet! &nbsp;See list at top of <a href="/how-to" target="_blank">this page</a> for compatible wallets)
                                    </div>
                            <input  ref="walletAddress" 
                                    type="text" 
                                    className = "form_input ht_175" 
                                    defaultValue = { this.profileJSON.walletAddress }
                            />
                        </div>

                        <div className="form_td">
                            slogan: <br/>
                            <input  ref="slogan" 
                                    type="text" 
                                    className = "form_input ht_175" 
                                    defaultValue = { this.profileJSON.slogan }
                            />
                        </div>

                        <div className="form_td padding_16_0">
                            choose up to 5 categories: <br/>
                            <CategoriesChoices checkedList = { this.profileJSON.categories }/>
                        </div>

                        <div className="form_td">
                            description of goods or services offered: <br/>
                            <textarea 
                                        ref         = "desc" 
                                        rows        = "10" 
                                        className   = "form_textarea"
                                        onChange    = { this.handleDescChange }
                                        value       = { this.state.descValue }
                            />
                        </div>

                        <div className="form_td">
                            profile photo (ideal size 180x180): { imageErrorDOM }<br/>
                            <div ref="avUploadBox" className = { this.state.avUploadBoxClassList.join(' ')}>
                                <svg className = "box__icon" xmlns="http://www.w3.org/2000/svg" width="50" height="43" viewBox="0 0 50 43"><path d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z"></path></svg><br /><br />
                                <label htmlFor="avatar_file" className="dk_gray margin_0_auto" ref="avatar_file_label">
                                    <input  className               = "box__file" 
                                            type                    = "file"
                                            id                      = "avatar_file"
                                            ref                     = "avatar_file"
                                    />
                                    { avatar_label_inner_html }
                                </label>

                            </div>
                            <div className = "box__uploading">Uploading...</div>
                            <div className = "box__success">Done!</div>
                            <div className = "box__error">Error! <span></span></div>
                        </div>

                        <div className="form_td">
                            profile background image (ideal size 980x240): { BGimageErrorDOM }<br/>
                            <div ref="bgUploadBox" className = { this.state.bgUploadBoxClassList.join(' ')}>
                                <svg className = "box__icon" xmlns="http://www.w3.org/2000/svg" width="50" height="43" viewBox="0 0 50 43"><path d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z"></path></svg><br /><br />
                                <label htmlFor="bg_file" className="dk_gray margin_0_auto" ref="bg_file_label">
                                    <input  className               = "box__file" 
                                            type                    = "file"
                                            id                      = "bg_file"
                                            ref                     = "bg_file"
                                    />
                                    { bg_image_label_inner_html }
                                </label>

                            </div>
                            <div className = "box__uploading">Uploading...</div>
                            <div className = "box__success">Done!</div>
                            <div className = "box__error">Error! <span></span></div>
                        </div>
                    </div>
                </form>
                { generalErrorDOM }
                <input  type            = "button" 
                        ref             = "editButton" 
                        className       = "button_sm form_submit" 
                        onClick         = { this.handleSubmit } 
                        defaultValue    = { this.state.sendButtonText }
                />

            </div>
            
            
        );
    }
}

export default ProfileForm;
