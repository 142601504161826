require("../styles/_Common.scss");

// done inline in base_admin.html to keep out of public webpack.js
//require("../../application/static/js/jquery.qrcode.min.js")
//require("../../application/static/js/qrcode.js")

import React from "react";

class AddWallet extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            publicKeyErrorText      : '',
            privateKeyErrorText     : '',
            passwordErrorText       : '',
            keystoreErrorText       : '',
            sendButtonText          : 'Send',
            successfulPost          : false
        };
          
        this.busyFetching   = false;
        this.handleSubmit   = this.handleSubmit.bind(this);
        this.reloadPage     = this.reloadPage.bind(this);
    }
    
    
    reloadPage() {
        window.location.reload();
    }
    
    handleSubmit() {
        var allowSubmit     = true;
        if (this.busyFetching) {
            return;
        }
        
        var name            = this.refs.name.value,
            email           = this.refs.email.value,
            publicKey      = this.refs.publicKey.value,
            privateKey     = this.refs.privateKey.value,
            password        = this.refs.password.value,
            keystoreJSON    = this.refs.keystoreJSON.value,
            origNumTokens   = this.refs.origNumTokens.value;
        
        if (publicKey == '') {
            this.setState( {
                publicKeyErrorText  : 'Public Key is required.'
            });
            
            allowSubmit = false;
        }

        if (privateKey == '') {
            this.setState( {
                privateKeyErrorText : 'Private Key is required.'
            });
            allowSubmit = false;
        }
        if (password == '') {
            this.setState( {
                passwordErrorText   : 'Password is required.'
            });
            allowSubmit = false;
        }
        if (keystoreJSON == '') {
            this.setState( {
                keystoreErrorText   : 'Keystore JSON is required.'
            });
            allowSubmit = false;
        }

    
        if (allowSubmit) {
            this.busyFetching  = true; // don't allow again until thank you page or error
            var theForm   = document.getElementById('theForm');

            this.setState( {
                sendButtonText      : 'Processing...'
            });

            
            var formData = new FormData();
                formData.append("csrf_token",   this.refs.csrf_token.value);
                formData.append("name",         name);
                formData.append("email",        email);
                formData.append("publicKey",   publicKey);
                formData.append("privateKey",  privateKey);
                formData.append("password",     password);
                formData.append("keystoreJSON", keystoreJSON);
                formData.append("origNumTokens",origNumTokens);

            var ajaxReq =             new XMLHttpRequest();

                ajaxReq.addEventListener(
                    "load", 
                    function() {
                        var responseJSON =          JSON.parse(ajaxReq.responseText);
                        if ( responseJSON["msg"] == "success" ) { 
                            
                            this.setState( {
                                successfulPost      : true,
                                postedName          : responseJSON['name'],
                                postedEmail         : responseJSON['email'],
                                postedPublicKey     : responseJSON['publicKey'],
                                postedPrivateKey    : responseJSON['privateKey'],
                                postedpassword      : responseJSON['password'],
                                postedKeystore      : responseJSON['keystoreJSON'],
                                postedNumTokens     : responseJSON['origNumTokens']
                            });
                            
                            
                            $(function() {
                            	$('#publicQR').qrcode( responseJSON['publicKey'] );
                                $('#privateQR').qrcode( responseJSON['privateKey'] );
                            });
                        }
                        this.busyFetching = false;
                        return;
                    }.bind(this), 
                    false
                );
                ajaxReq.addEventListener(
                    "error", 
                    function(xhr, status, err) {
                        console.error("/trdadmin/add_wallet", status, err.toString());
                        this.setState( {
                            successfulPost      : false,
                            sendButtonText      : 'Send'
                        });
                        this.busyFetching = false;
                        return;
                    }.bind(this), 
                    false
                );
                ajaxReq.open( "post", "/trdadmin/add_wallet", true );
                ajaxReq.setRequestHeader("X-CSRF-Token", csrfToken);
                ajaxReq.setRequestHeader('X-Requested-With','XMLHttpRequest');
                ajaxReq.send(formData);
        }
    }

    
    render() {      
        var publicKeyErrorDOM   = (this.state.publicKeyErrorText)
                                    ?   <div className="errors">
                                            { this.state.publicKeyErrorText }
                                        </div>
                                    :   null;
        var privateKeyErrorDOM  = (this.state.privateKeyErrorText)
                                    ?   <div className="errors">
                                            { this.state.privateKeyErrorText }
                                        </div>
                                    :   null;
        var passwordErrorDOM    = (this.state.passwordErrorText)
                                    ?   <div className="errors">
                                            { this.state.passwordErrorText }
                                        </div>
                                    :   null;
        var keystoreErrorDOM    = (this.state.keystoreErrorText)
                                    ?   <div className="errors">
                                            { this.state.keystoreErrorText }
                                        </div>
                                    :   null; 
                                            
                                            
        if ( this.state.successfulPost ) {
            var mainDOM =   <div>
                                <h1><u>Store in a safe place!<br />If a person gets these keys, they can steal your tokens! </u></h1> 
                                <br /> 
                                <table className='container' className='border_none'> 
                                    <tbody>
                                        <tr> 
                                            <td className = 'right_padded bold'> 
                                                name: 
                                            </td> 
                                                <td className = 'left_padded'>{ this.state.postedName } 
                                            </td> 
                                        </tr> 
        
                                        <tr> 
                                            <td className = 'right_padded bold'> 
                                                email: 
                                            </td> 
                                            <td className = 'left_padded'>{ this.state.postedEmail } 
                                            </td> 
                                        </tr> 
        
                                        <tr> 
                                            <td className = 'right_padded bold'> 
                                                Address (public key): 
                                            </td> 
                                            <td className = 'left_padded'>{ this.state.postedPublicKey }  
                                            </td> 
                                        </tr> 
        
                                        <tr> 
                                            <td className = 'right_padded bold'> 
                                                private key (unencrypted): 
                                            </td> 
                                            <td className = 'left_padded'>{ this.state.postedPrivateKey } 
                                            </td> 
                                        </tr> 
        
                                        <tr> 
                                            <td className = 'right_padded bold'> 
                                                password: 
                                            </td> 
                                            <td className = 'left_padded'>{`→${this.state.postedpassword}←`}
                                            </td> 
                                        </tr> 
                                        <tr> 
                                            <td className = 'right_padded bold'> 
                                                keystore JSON: 
                                            </td> 
                                            <td className='keystore'>{ this.state.postedKeystore } 
                                            </td> 
                                        </tr> 
                                        <tr> 
                                            <td className = 'right_padded bold'> 
                                                original number of tokens: 
                                            </td> 
                                            <td className = 'left_padded'>{ this.state.postedNumTokens } 
                                            </td> 
                                        </tr> 
                                        <tr> 
                                            <td className = 'right_padded bold'> 
                                                Tradesman (TRD) contract address: 
                                            </td> 
                                            <td className = 'left_padded'>0xE9e03497fBF8f9463b2124fe2c8CeB8C3C92d8CD</td> 
                                        </tr> 
                                        <tr> 
                                            <td className='padding_20'> 
                                                Public Key: <br /><br /> 
                                                <div id='publicQR'></div> 
                                            </td> 
                                            <td className='padding_20'> 
                                                Private Key (unencrypted): <br /><br /> 
                                                <div id='privateQR'></div> 
                                            </td> 
                                        </tr> 
                                    </tbody>
                                </table>
        
                                <br /> 
                                <button className='button_sm form_submit' onClick={ this.reloadPage }>Add another</button>
                            </div>
        } else {
            var mainDOM =   <div>
                                <br /><br />
                                <h3>Add Wallet to datastore, then print.</h3>
                                Go to bookmark: file:///Users/krsimpson/junk/Crypto/etherwallet-v3.11.2/index.html to create wallet, saving keys here.
                                <br /><br />

                                <form id="theForm" name="theForm" action="" method="post" encType="multipart/form-data"> 
                                    <input  ref         = "csrf_token" 
                                            type        = "hidden" 
                                            defaultValue= { this.props.globalProps.csrfToken } 
                                    />

                                    <div className = "align_left">
                                            password*: { passwordErrorDOM }<span>{`sample from: ${this.props.globalProps.varsJSON.random_pw}`}</span><br/>
                                        <input ref="password" type="text"  className="form_input ht_175" />
                                    </div>


                                    <div className = "align_left">
                                        keystore JSON*: { keystoreErrorDOM }<br/>
                                        <textarea ref="keystoreJSON" className="form_input height_360p"></textarea>
                                    </div>
                                            
                                    <div className = "align_left"> 
                                        privateKey*: { privateKeyErrorDOM }<br/>
                                        <input ref="privateKey" type="text" className="form_input ht_175" />
                                    </div>
                                            
                                    <div className = "align_left"> 
                                        publicKey*: { publicKeyErrorDOM }<br/>
                                        <input ref="publicKey" type="text" className="form_input ht_175" />
                                    </div>
                                    
                                    <div className = "align_left">
                                        name: <br/>
                                        <input ref="name" type="text" className="form_input ht_175" />
                                    </div>

                                    <div className = "align_left">
                                        email: <br/>
                                        <input ref="email" type="text" className="form_input ht_175"  />
                                    </div>
                                            
                                    <div className = "align_left">
                                        orig num tokens: <br/>
                                        <input ref="origNumTokens" type="text" />
                                    </div>

                                </form>
                                <input type="button" id="sendButton" className="button_sm form_submit" onClick= { this.handleSubmit } value={ this.state.sendButtonText } />
                            </div>

        }
                                    
                                    
                                    
                                    
        return (
            <div className="container center_this max_width_760">
                { mainDOM }
            </div>
        )
    }
}


export default AddWallet;
