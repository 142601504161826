require("../styles/_Common.scss");
require("../styles/_Forms.scss");

import React from "react";

class Contact extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
            name_error              : false,
            email_error             : false,
            phone_error             : false, // phone not required
            message_error           : false,
            files_error             : false,
            send_button_text        : "Send",
            label_html              : "orig",
            upload_box_class_list   : ["uploadBox", "box__input", "center_this"],
            thank_you               : false,
            msg_value               : ''
        };
        
        this.PROHIBITED_EXTENSIONS = [".adp", ".app", ".asp", ".bas", ".bat", ".cer", ".chm", ".cmd", ".cnt", ".cpl", ".crt", ".csh", ".der", ".exe", ".fxp", ".gadget", ".hlp", ".hpj", ".hta", ".inf", ".ins", ".isp", ".its", ".js", ".jse", ".ksh", ".lnk", ".mad", ".maf", ".mag", ".mam", ".maq", ".mar", ".mas", ".mat", ".mau", ".mav", ".maw", ".mda", ".mdb", ".mde", ".mdt", ".mdw", ".mdz", ".msc", ".msh", ".msh1", ".msh2", ".mshxml", ".msh1xml", ".msh2xml", ".msi", ".msp", ".mst", ".ops", ".osd", ".pcd", ".pif", ".plg", ".prf", ".prg", ".pst", ".reg", ".scf", ".scr", ".sct", ".shb", ".shs", ".ps1", ".ps1xml", ".ps2", ".ps2xml", ".psc1", ".psc2", ".tmp", ".url", ".vb", ".vbe", ".vbp", ".vbs", ".vsmacros", ".vsw", ".ws", ".wsc", ".wsf", ".wsh", ".xnk", ".ade", ".cla", ".class", ".grp", ".jar", ".mcf", ".ocx", ".pl", ".xbap"];
        
        
        this.addUploadBoxClass      = this.addUploadBoxClass.bind(this);
        this.removeUploadBoxClass   = this.removeUploadBoxClass.bind(this);
        this.showFiles              = this.showFiles.bind(this);
        this.handleSubmit           = this.handleSubmit.bind(this);
        this.handleMessageChange    = this.handleMessageChange.bind(this);
        this.busyFetching           = false;
        this.files                  = [];
    }
    
    componentDidMount() {
        if ( getIEVersion() < 10 ) {
            return;
        }
        
        this.uploadBox          = this.refs.uploadBox;
        this.form               = this.refs.theForm;

        this.fileInput          = this.form.querySelector( 'input[type="file"]' );
        //this.errorMsg         = this.form.querySelector( '.box__error span' );
        //this.restart          = this.form.querySelectorAll( '.box__restart' );
        
        var fakeDiv             = document.createElement('div');
        var hasAdvancedUpload   = ('draggable' in fakeDiv) || ('ondragstart' in fakeDiv && 'ondrop' in fakeDiv);
            hasAdvancedUpload   = hasAdvancedUpload && ('FormData' in window);
            hasAdvancedUpload   = hasAdvancedUpload && ('FileReader' in window);
            
            
        if (hasAdvancedUpload) {
            this.addUploadBoxClass('has-advanced-upload');

            ['drag', 'dragstart', 'dragend', 'dragover', 'dragenter', 'dragleave', 'drop'].forEach( function(e) {
                this.uploadBox.addEventListener(e, function(e) {
                    e.preventDefault();
                    e.stopPropagation();
                });
            }.bind(this));
            ['dragover', 'dragenter'].forEach( function(e) {
                this.uploadBox.addEventListener(e, function() {
                    this.addUploadBoxClass('is-dragover');
                }.bind(this));
            }.bind(this));
            ['dragleave', 'dragend', 'drop'].forEach( function(e) {
                this.uploadBox.addEventListener(e, function() {
                    this.removeUploadBoxClass('is-dragover');
                }.bind(this));
            }.bind(this));
            //['drop'].forEach( function(e) {
                this.uploadBox.addEventListener('drop', function(e) {
                    if (e.originalEvent) {
                        this.files = e.originalEvent.dataTransfer.files;
                    } else {
                        this.files = e.dataTransfer.files;
                    }
                    this.showFiles( this.files );
                    
                    this.setState( 
                        {
                            files_error             : false
                        }
                    );
    
                    //document.getElementById("file").value = this.files;
                    //var ajaxData = new FormData( this.form );
    
                    //this.files.forEach( function( file ) {
                    //    ajaxData.append( this.fileInput.getAttribute( 'name' ), file );
                    //});

                }.bind(this));  
                //});
            
        }
        this.fileInput.addEventListener('change', function(e) {
            if(!e.target.files || !window.FileReader) return;
            
            this.files = e.target.files;
            
            this.showFiles( e.target.files );
            
            this.setState( 
                {
                    files_error             : false
                }
            );

        }.bind(this));  
    } 
    
    handleMessageChange(e) {
        this.setState(
            {
                msg_value           : e.target.value
            }
        );
    }
    

    addUploadBoxClass(className) {
        var upload_box_class_list = this.state.upload_box_class_list;
        if ( upload_box_class_list.indexOf(className) == -1 ) { // if not already there
            upload_box_class_list.push(className)
            this.setState(
                {
                    upload_box_class_list : upload_box_class_list
                }
            );
        }
    }

    removeUploadBoxClass(className) {
        var upload_box_class_list = this.state.upload_box_class_list;
        
        var index = upload_box_class_list.indexOf(className);
        
        if (index !== -1) {                         // if it's there
            upload_box_class_list.splice(index, 1);
            this.setState(
                {
                    upload_box_class_list : upload_box_class_list
                }
            );
        }
        
    }

    showFiles(files) {
        var text = (files.length > 1) 
                        ? files.length + " files selected"
                        : files[0].name;
        this.setState(
            {
                label_html              : text
            }
        );
    }
    
    
    handleSubmit(e) {
        var allowSubmit    = true;
        
        this.setState( {
            name_error              : false,
            email_error             : false,
            phone_error             : false, // phone not required
            message_error           : false,
            files_error             : false
        });
        
        if (this.busyFetching) {
            return;
        }
        
        var name            = this.refs.name.value.trim(),
            org             = this.refs.org.value,
            email           = this.refs.email.value.trim(),
            phone           = this.refs.phone.value.trim(),
            message         = this.refs.message.value.trim(),
            walletAddress   = this.refs.walletAddress.value.trim();
        
        var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\"\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        
        if (name == '') {
            this.setState( {
                name_error : true
            });
            allowSubmit = false;
        }
        if (!re.test(email)) {
            this.setState( {
                email_error : true
            });
            allowSubmit = false;
        }

        if (message == '') {
            this.setState( {
                message_error : true
            });
            allowSubmit = false;
        }
    
        if (allowSubmit) {
            this.busyFetching   = true; // don't allow again until thank you page or error
        
            this.addUploadBoxClass('is-uploading');
            this.removeUploadBoxClass('is-error');  
            
            this.setState(
                {
                    send_button_text    : 'Processing...'
                }
            );
        
            //if (this.files.length == 0) {
            //    this.form.submit();   // no need to send ajax
            //    return;
            //}
        
            var formData = new FormData();
                formData.append("name",             name);
                formData.append("org",              org);
                formData.append("email",            email);
                formData.append("phone",            phone);
                formData.append("walletAddress",   this.refs.walletAddress.value);
                formData.append("message",          message);
            
            var totalFileSize = 0;
            
    		Array.prototype.forEach.call( this.files, function( file ) {
                for (var ii = 0; ii < this.PROHIBITED_EXTENSIONS.length; ii++) {
                    if ( file.name.indexOf(this.PROHIBITED_EXTENSIONS[ii]) > -1 ) {

                        this.busyFetching   = false;
                        allowSubmit         = false;
                        this.files          = [];

                        //this.addUploadBoxClass('is-error');
                        this.addUploadBoxClass('has-advanced-upload');

                        //this.removeUploadBoxClass('has-advanced-upload');
                        this.removeUploadBoxClass('is-uploading');
                        
                        this.setState(
                            {
                                files_error         : true,
                                files_error_text    : 'Prohibited file type: ' + file.name,
                                send_button_text    : 'Send',
                                label_html          : 'orig'
                            }
                        );
                        this.busyFetching   = false;
                        return;
                    }
                }
                
                totalFileSize += file.size;
                
                // cloud tasks have 1MB file limit, so... If require more than 1MB, sned dirently and bypass task queue
                if (totalFileSize > 900000) {
                    this.setState(
                        {
                            files_error         : true,
                            files_error_text    : 'File size limit exceeded: ' + Math.round(totalFileSize/1024) + 'kb. Please reduce to 900kb total',
                            send_button_text    : 'Send',
                            label_html          : 'orig'
                        }
                    );
                    allowSubmit = false;
                    this.busyFetching   = false;
                    return;
                }

    			formData.append( "files[]", file );

    		}.bind(this));

            if (!allowSubmit) return;   // malware file extension
            
            this.setState(
                {
                    label_html          : 'busy'
                }
            );
            
            fetch('/contact', {
                method    : 'POST',
                headers   : {
                    'Accept'        : 'application/json',
                    //'Content-Type'  : 'application/json; charset=UTF-8',
                    'X-CSRFToken'   : csrfToken
                },
                mode      : 'same-origin',
                body      : formData
            })
            .then(
                function(response) {
                    if (response.status !== 200) {
                        console.log('/contact Status Code: ' + response.status);
                        this.setState( 
                            {
                                errorText: response.status + "error.  Please try again."
                            }
                        );
                        return;
                    }

                    response.json().then(function(responseJSON) {
                        //console.error(responseJSON)
                        if ( responseJSON["msg"] == "success" ) { 
                            this.removeUploadBoxClass('is-uploading');

                            this.setState(
                                {
                                    thank_you : true
                                }
                            )
                            this.busyFetching = false;
                            return;
                        } else {
                            this.setState( 
                                {
                                    errorText: responseJSON["msg"]
                                }
                            );
                        }
                    }.bind(this));
                }.bind(this)
            )
            .catch(function(err) {
                console.log('/contact Fetch Error :-S', err);
                this.removeUploadBoxClass('is-uploading');
                document.getElementById('sendButton').value = 'Send';
                alert( 'Problem sending file.  You may need to email it separately.' );
                this.setState(
                    {
                        label_html          : 'orig'
                    }
                );
                this.busyFetching = false;

                return;
            }.bind(this));
        }
    }
 

    render() {
        
        if ( getIEVersion() < 10 ) {
            return (
                <div />
            )
        }

        if ( this.state.thank_you ) {
            return (
                <div className = "container center_this">
                    <br /><br /><br /><br />
                    Thank you!  We will be contacting you shortly.
                </div>
            )  
        } 
        
        
        var nameErrorDOM    = ( this.state.name_error )
                                ? <span className="errors">Name is required.</span>
                                : null;
        
        var emailErrorDOM   = ( this.state.email_error )
                                ? <span className="errors">Invalid email address.</span>
                                : null;

        var phoneErrorDOM   = ( this.state.phone_error )
                                ? <span className="errors">Phone number is required.</span>
                                : null;

        var messageErrorDOM = ( this.state.message_error )
                                ? <span className="errors">Please tell us what you need.</span>
                                : null;
                                
        var filesErrorDOM   = ( this.state.files_error )
                                ? <span className="errors">{ this.state.files_error_text }</span>
                                : null;
                                
        var label_inner_html = this.state.label_html;
                                
        switch(this.state.label_html) {
            case "orig":
                label_inner_html =  <div>
                                        <div className = "button_sm inline_block_white">
                                            Choose files 
                                        </div>
                                        <span className = "box__dragndrop"> &nbsp;&nbsp;or drag them here</span>
                                    </div>
                break;
            case "busy":
                label_inner_html =  <div>
                                        <img src="/static/img/waiting.gif" className = "busy_gif" alt="" />
                                    </div>
                break;
            default:
                label_inner_html = this.state.label_html;
        }
                                    
        var csrf_token = csrfToken;
                                
        return (
            <div className = "container center_this">
                <br /><br />
                Contact Us with questions.
                <br /><br />
                Send us your proof of barter or non-profit work (invoice, letter, etc.), and we&apos;ll send you your free bonus tokens!<br /><br />
                <form   ref     = "theForm" 
                        action  = "" 
                        method  = "post" 
                        encType = "multipart/form-data"
                > 
                    <input type="hidden" name="csrf_token" value={ csrf_token }/>
                    <div className = "margin_0_auto table_resp align_left">

                        <div className="form_td">
                            name*: { nameErrorDOM }<br/>
                            <input ref="name" type="text" className = "form_input ht_175" />
                        </div>

                        <div className="form_td">
                            organization:<br/>
                            <input ref="org" type="text" className = "form_input ht_175" />
                        </div>

                        <div className="form_td_sm inline_block">
                            email*: { emailErrorDOM }<br/>
                            <input ref="email" type="text" className = "form_input_sm ht_175" />
                        </div>
                        <div className="form_td_sm inline_block"> 
                            phone: { phoneErrorDOM }<br/>
                            <input ref="phone" type="text" className = "form_input_sm ht_175" />
                        </div>

                        <div className="form_td">
                            ETH wallet address: <br/>
                            <input ref="walletAddress" type="text" className = "form_input ht_175" />
                        </div>

                        <div className="form_td">
                            message*: { messageErrorDOM }<br/>
                            <textarea   ref="message" 
                                        rows="10"
                                        className = "form_textarea"
                                        onChange    = { this.handleMessageChange }
                                        value       = { this.state.msg_value }
                            />
                        </div>

                        <div className="form_td">
                            attach files &amp; photos: { filesErrorDOM }<br/>
                            <div ref="uploadBox" className = { this.state.upload_box_class_list.join(' ')}>
                                <svg className = "box__icon" xmlns="http://www.w3.org/2000/svg" width="50" height="43" viewBox="0 0 50 43"><path d="M48.4 26.5c-.9 0-1.7.7-1.7 1.7v11.6h-43.3v-11.6c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v13.2c0 .9.7 1.7 1.7 1.7h46.7c.9 0 1.7-.7 1.7-1.7v-13.2c0-1-.7-1.7-1.7-1.7zm-24.5 6.1c.3.3.8.5 1.2.5.4 0 .9-.2 1.2-.5l10-11.6c.7-.7.7-1.7 0-2.4s-1.7-.7-2.4 0l-7.1 8.3v-25.3c0-.9-.7-1.7-1.7-1.7s-1.7.7-1.7 1.7v25.3l-7.1-8.3c-.7-.7-1.7-.7-2.4 0s-.7 1.7 0 2.4l10 11.6z"></path></svg><br /><br />
                                <label htmlFor="file" className="dk_gray margin_0_auto" ref="label">
                                    <input  className               = "box__file" 
                                            type                    = "file"
                                            id                      = "file"
                                            //data-multiple-caption   = ";count; files selected" 
                                            multiple 
                                    />
                                    { label_inner_html }
                                </label>
            
                            </div>
                            <div className = "box__uploading">Uploading...</div>
                            <div className = "box__success">Done!</div>
                            <div className = "box__error">Error! <span></span></div>
                        </div>

                    </div>
                </form>
                <input  type            = "button" 
                        id              = "sendButton" 
                        className       = "button_sm form_submit" 
                        onClick         = { this.handleSubmit } 
                        defaultValue    = { this.state.send_button_text } 
                />
    
            </div>
        )            
    }
}


export default Contact;
