require("../styles/_Common.scss");
require("../styles/_Forms.scss");

import React from "react";

class Footer extends React.Component {
    
    constructor(props) {
        super(props);
        
        this.state = {
        };
    }
    
  render() {
      
      return (
        <div className = "container">
            <div className = "footer_cover" />
            <footer className = "container">
                <div id="footer" className = "container">

                    <a className = "btn btn-link rem_9" href="/media">
                        <span>Media</span>
                    </a>

                    <span className = "btn lt_gray">&nbsp;&bull;&nbsp;</span>
                    <a className = "btn btn-link rem_9" href="/terms">
                        <span>Terms</span>
                    </a>

                    <span className = "btn lt_gray">&nbsp;&bull;&nbsp;</span>
                    <a className = "btn btn-link rem_9" href="/privacy">
                        <span>Privacy</span>
                    </a>

                    <span className = "btn lt_gray">&nbsp;&bull;&nbsp;</span>
                    <a className = "btn btn-link rem_9" href="/contact">
                    <i className = "fa fa-envelope" aria-hidden="true"></i>
                        <span className = "padding_left_05em">Contact Us</span> 
                    </a>

                </div>       
            </footer>
        </div>
      )
   }
}

export default Footer;